import React from "react";
import { Navigate, useLocation } from "react-router-dom";


function PrivateRoute({ children, allowedRoles }) {
  const location = useLocation()
  // console.log(allowedRoles, "allowedRoles")

  let userRole = localStorage.getItem('role')

  const navigateToLogin = () => {

    let result = location.pathname.includes("/trackpage");
    let resultArtist = location.pathname.includes("/artist");
    let joinresult = location.pathname.includes("/join");
    let createresult = location.pathname.includes("/upload") || location.pathname.includes("/mintnft");

    if (result === true || resultArtist === true || joinresult === true || createresult === true) {

      const message = `${location.pathname}`

      localStorage.setItem("URL", message)

    }
    const stateData = { login: 0 };

    return (
      <>
        <Navigate
          to={'/'}
          state={stateData}
        />
      </>
    );
  };

  const roleNotAllowed = allowedRoles && !allowedRoles.includes(userRole);
  const state = { login: 1 };

  if (roleNotAllowed) {
    return <Navigate to="/"
      state={state}
    />;
  }

  return localStorage.getItem("iphephile") !== null ? children : navigateToLogin();
}

export default PrivateRoute;
