import React, { useEffect, useState, useRef } from 'react'
import './LoginNew.css'
import dmlogo from '../../img/dster-webp/dwhite.webp'
import lognew from '../../img/dster-webp/lognew.webp'
import { Button, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useNavigate, useLocation } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    GoogleAuthProvider,
    signInWithPopup,
    onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../fireBase/firebase";
import ReCAPTCHA from "react-google-recaptcha";
import consts from '../../Constant'
import Axios from '../../Axios'
import { ToastContainer, toast } from 'react-toastify'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function RegisterNew() {

    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const lgScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));


    const navigate = useNavigate()
    const [select, setSelect] = useState(null)
    const handleSelect = (val) => {
        setSelect(val)
        const timer = setTimeout(() => {
            setScreen(true)
        }, 400);

        return () => clearTimeout(timer);

    }

    // console.log(select, 'select');

    const [chk, setChk] = useState(false)

    const handleChk = () => {
        setChk(!chk)
        setAgreeError('')
    }

    const [screen, setScreen] = useState(false)

    const handleScreen = () => {
        setScreen(false)
    }


    const [otp, setOtp] = useState(false)
    const [otpValue, setOtpValue] = useState()
    const handleOtp = () => {
        setOtp(true)
    }


    const [regComponent, setRegComponent] = useState(true)


    //register integration

    const history = useLocation();
    const isMounted = useRef(false);

    const [recent, setResent] = useState(false);
    const [recent1, setResent1] = useState(false);



    useEffect(() => {
        email?.current?.focus();
        if (!isMounted.current) {
            if (history?.state?.emailVerify === 0) {
                toast.success("Email Verified Successfully Please Login", {
                    icon: "👏",
                });
                navigate(`/`, { state: { emailVerify: 2 } });
            } else if (history?.state?.emailVerify === 1) {
                toast.error("Email Already Verified");
                navigate(`/`, { state: { emailVerify: 2 } });
            } else if (history?.state?.login === 0) {
                toast.error("Please Login");

                navigate({ state: { login: 2 } });

                setTimeout(() => {
                    setOpen(true);
                }, 1500);
            } else if (history?.state?.email) {
                // toast.error(history?.state?.email)
                email.current.value = history?.state?.email;
            }
            isMounted.current = true;
        } else {
            // console.log("fetched", history?.state)
        }
    }, []);

    const [openpass, setOpenPass] = React.useState(false);
    const handleOpenTime = () => setOpenPass(true);
    const handleCloseTime = () => setOpenPass(false);

    const [showPasswordone, setShowPasswordone] = React.useState(false);
    const handleClickShowPasswordone = () => setShowPasswordone((show) => !show);

    const [today, setDate] = useState(new Date());
    const [login, setLogin] = useState(false);
    const [otperr, setOtpErr] = useState('');

    const email = useRef(null);
    const fname = useRef(null);
    const lname = useRef(null);
    const captchaRef = useRef(null);

    // const phone = useRef(null);
    const remail = useRef(null);
    const inpassref = useRef(null);
    const inputconfrimRef = useRef(null);

    const [lnameerr, setLnameerr] = useState(null);
    const [wnameerr, setWnameerr] = useState(null);
    const [fnameerr, setFnameerr] = useState(null);
    const [checkBoxError, setCheckBoxError] = useState(null);
    const [usertypeError, setUsertypeError] = useState(null);
    const [islogin, setIslogin] = useState(false);
    const [islogin2, setIslogin2] = useState(false);
    const [islogin3, setIslogin3] = useState(false);
    const [agreeError, setAgreeError] = useState()

    //const token = window.localStorage.getItem("iphephile")
    const tokens = localStorage.getItem("iphephile");

    const current = new Date();
    const date = current.toLocaleString("en-us", {
        month: "long",
        year: "numeric",
        day: "numeric",
    });
    // console.log(otpValue, fname?.current?.value, 'screen');

    useEffect(() => {
        const timer = setInterval(() => {
            // Creates an interval which will update the current data every minute
            // This will trigger a rerender every component that uses the useDate hook.
            setDate(new Date());
        }, 60);
        // return () => {
        //   clearInterval(timer);
        // }
    }, []);

    const time = today.toLocaleTimeString({
        hour: "numeric",
        hour12: true,
        minute: "numeric",
    });

    useEffect(() => {
        const store = localStorage.setItem("nickname", "mynameglad");
        if (localStorage.getItem("nickname")) {
            setLogin(true);
        } else {
            setLogin(false);
        }
    }, []);

    const [open, setOpen] = useState(false);
    const [emailerr, setemailerr] = useState(null);
    const [passworderr, setpassworderr] = useState(null);
    const [cpassworderr, setcpassworderr] = useState(null);
    const [error, setError] = useState("");
    const [userType, setUserType] = useState("user");
    const [regMail, setRegmail] = useState('');
    const [web3, setWeb3] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [verify, setVerify] = useState(false)
    const [remailerr, setremailerr] = useState(null);


    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [showPassword1, setShowPassword1] = React.useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    const [password, setPassword] = React.useState(false);

    const handleClickPassword = () => setPassword((show) => !show);

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const emailhandleChange = () => {
        // console.log('emailhandleChange');

        const emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const inputValue = email.current.value;
        // setLnameerr(null);
        setWnameerr(null);
        if (inputValue.trim() === "") {
            setemailerr("Email is Required");
        } else if (!emailRegex.test(inputValue)) {
            setemailerr("*Invalid Email");
        } else {
            setemailerr("");
        }
    };
    const handlePasswordChange = () => {
        const passwordregex = new RegExp(
            /(^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(?=.*\d).{8,16}$)+/,
            "gm"
        );
        const inputValue = inpassref.current.value;
        // setLnameerr(null);
        if (inputValue.trim() === "") {
            setpassworderr("");
        } else if (!passwordregex.test(inputValue)) {
            setpassworderr(
                "Password must be at least 8 characters and It contains Minimum one uppercase and Special Characters"
            );
        } else {
            setpassworderr("");
        }
    };

    const handleConfirmPasswordChange = () => {
        setLnameerr(null);
        // setcpassworderr(inputconfrimRef);
        if (inputconfrimRef.current.value !== inpassref.current.value) {
            setcpassworderr("Password do not match");
        } else if (inputconfrimRef === inpassref) {
            setcpassworderr("");
        } else {
            setcpassworderr("");
        }
    };

    const handlefirstnamechange = (e) => {
        const re = /^[A-Za-z]+$/
        if (e.target.value != '' && !re.test(e.target.value)) {
            setFnameerr('Please Enter Valid Name')
        }
        else {
            setFnameerr(null);
        }
    };

    const handlelastnamechange = (e) => {
        const re = /^[A-Za-z]+$/
        if (e.target.value != '' && !re.test(e.target.value)) {
            setLnameerr('Please Enter Valid Name')
        }
        else {
            setLnameerr(null);
        }

    };


    const handleError = () => {
        fname.current.value = ''
        lname.current.name = ''
        email.current.name = ''
        inpassref.current.value = ''
        inputconfrimRef.current.value = ''
        setSelect('')
        setChk(false)
        setOtp(true)

    }

    const handleBack = () => {
        setFnameerr(null)
        setLnameerr(null)
        setemailerr(null)
        setpassworderr(null)
        setcpassworderr(null)
        setUsertypeError(null)
        setAgreeError(null)
        setCheckBoxError(null)
        setError(null)
    }

    var element = document.getElementById("box");
    const register = async () => {

        try {
            const emailRegex =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const passwordregex = new RegExp(
                /(^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(?=.*\d).{8,16}$)+/,
                "gm"
            );
            // console.log(fnameerr, 'fnamerr');

            if (!fname.current.value) {
                setFnameerr("*FirstName is Required");
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            else if (fnameerr != null) {
                setFnameerr(fnameerr)
            }
            else if (!lname.current.value) {
                setLnameerr("*LastName is Required");
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            else if (lnameerr != null) {
                setLnameerr(lnameerr)
            }
            else if (!email.current.value) {
                setemailerr("*Email is Required");
                // element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            else if (!emailRegex.test(email.current.value)) {
                setemailerr("*Invalid Email");
                // element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            else if (!inpassref.current.value) {
                setpassworderr("*Password is Required");
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            else if (!passwordregex.test(inpassref.current.value)) {
                setpassworderr(
                    "*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234"
                );
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            else if (!inputconfrimRef.current.value) {
                setcpassworderr("*Confirm Password is Required");
                element.scrollIntoView({ behavior: "smooth", block: "start" });

            }
            else if (inpassref.current.value !== inputconfrimRef.current.value) {
                setcpassworderr("*Password and Confirm Password must be Equal");
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            else if (select == "") {
                setUsertypeError("*Please Select User Type");
                element.scrollIntoView({ behavior: "smooth", block: "end" });
            }
            else if (!chk) {
                setAgreeError('Please Select Agree Terms and Conditions')
            }
            else if (!captchaRef.current.getValue()) {
                setCheckBoxError("Please Verify Your A Human");
            }
            else {
                let token = captchaRef.current.getValue();
                // console.log(token, "RECAPTCHAAA");
                if (token) {
                    setError("");
                } else {
                    setError("You must confirm you are not a robot");
                }
                setIslogin(true);
                const name = `${fname.current.value} ${lname.current.value}`;
                const registerdata = {
                    name: name,
                    firstname: fname.current.value,
                    lastname: lname.current.value,
                    email: email.current.value,
                    signup_type: "gmail",
                    // phone: phone.current.value,
                    // typeUser: userType,
                    typeUser: select,
                    password: inpassref.current.value,
                    repassword: inputconfrimRef.current.value,
                };

                const { data } = await Axios.post("/register", registerdata);

                if (data.result) {
                    toast.success("Registred Successfully, Please Verify Your Email");
                    localStorage.clear();
                    await localStorage.setItem("regMail", email.current.value);
                    await setRegmail(localStorage.getItem("regMail"));
                    // setResent(true);
                    handleError()
                    // setOtp(true)
                    setTimeout(() => {
                        setIslogin(false);
                        // navigate("/");
                    }, 2000);
                }
            }
        } catch (error) {
            // console.log(error, "err")
            if (error?.response?.data?.message === "EMAIL ALREADY EXISTS") {
                toast.error("Email already Exist");
            } else {
                setemailerr(error?.response?.data?.message);
                toast.error(error?.response?.data?.message);
            }
            setTimeout(() => {
                setIslogin(false);
            }, 2000);
        }

        // setResent(true)
    };

    const signinwithGoogle = async (e) => {
        try {
            // if (userType == "") {
            //     setUsertypeError("*Please Select User Type");
            //     element.scrollIntoView({ behavior: "smooth", block: "end" });
            // } 
            if (select == "") {
                setUsertypeError("*Please Select User Type");
                element.scrollIntoView({ behavior: "smooth", block: "end" });
            }

            else if (!captchaRef.current.getValue()) {
                setCheckBoxError("Please Verify Your A Human");
            } else {
                const provider = await new GoogleAuthProvider();
                return signInWithPopup(auth, provider)
                    .then((res) => {
                        onAuthStateChanged(auth, async (user) => {
                            // console.log(user, 'user');

                            if (user) {
                                const registerdata = {
                                    name: user.displayName,
                                    firstname: user.displayName,
                                    lastname: user.displayName,
                                    email: user.email,
                                    signup_type: "google",
                                    // typeUser: userType,
                                    typeUser: select,

                                };
                                setIslogin2(true);

                                await Axios.post("/register", registerdata)
                                    .then((res) => {
                                        if (res.data.success) {
                                            toast.success(res.data.message);
                                            window.localStorage.setItem(
                                                "iphephile",
                                                res?.data?.result?.token
                                            );
                                            window.localStorage.setItem(
                                                "usrId",
                                                res?.data?.result?.user?._id
                                            );
                                            window.localStorage.setItem(
                                                "role",
                                                res?.data?.result?.user?.role
                                            );
                                            window.localStorage.setItem("isPauses", false);
                                            window.localStorage.setItem("currents", 0);

                                            setTimeout(() => {
                                                setIslogin2(false);
                                                navigate("/");
                                            }, 2000);
                                        } else {
                                            toast.error(res.data.message);
                                            setTimeout(() => {
                                                setIslogin2(false);
                                            }, 2000);
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);

                                        toast.error(error?.response?.data?.message);

                                        setTimeout(() => {
                                            setIslogin2(false);
                                        }, 2000);
                                        // toast.error("Error while Register to dreamster")
                                    });
                            } else {
                                console.log("user");
                            }
                        });
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message);
                        setTimeout(() => {
                            setIslogin2(false);
                        }, 1000);
                    });
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            setTimeout(() => {
                setIslogin2(false);
            }, 1000);
        }
    };
    useEffect(() => {
        const unsubscripe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;

            } else {
                console.log("user");
            }
        });
    }, []);

    const handlecheckBox = () => {
        setCheckBoxError("");
    };

    const [verifyEmail, setVerifyEmail] = useState(false)

    // console.log(verifyEmail, otp, 'verifyemail');


    const [response, setResponse] = useState("");
    const handleCaptchaSuccess = (value) => {
        setResponse(value);
        Axios.post("/verify-recaptcha", { response: value })
            .then((res) => {
                if (res.data.success) {
                    // Perform desired action on successful verification
                } else {
                    // Display error message to the user
                }
            })
            .catch((err) => console.error(err));
    };

    const handleUserTypeChange = (event) => {
        setUserType(event.target.value);
        setUsertypeError("");
    };




    const handleNewVerify = async () => {
        try {
            const payload = {
                otpcode: otpValue,
                email: regMail
            };
            setIslogin3(true)
            const { data } = await Axios.post("/verify", payload);

            if (data?.success) {
                toast.success(data?.message);
                setTimeout(() => {
                    toast.success('Please Login');
                }, 2500)
                setTimeout(() => {
                    navigate("/login");
                    setIslogin3(false)
                }, 1500);
            } else {
                // toast.error(data?.message);
                setOtpErr(data?.message);
                setIslogin3(false)
            }
        } catch (error) {
            setIslogin3(false)
            setOtpErr(error?.response?.data?.message);
        }
    }


    const handleresendOpen = () => {
        setOpen(false);
        setResent1(true);
        setremailerr("");
    };
    const handleresendClose = () => {
        setResent1(false);
        setOpenPass(false);
        setremailerr("");
    };

    const ressendEmail = async () => {
        try {
            const emailRegex =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!remail.current.value) {
                setremailerr("*Email is Required");
            } else if (!emailRegex.test(remail.current.value)) {
                setremailerr("*Invalid Email");
            } else {
                // console.log("Login")
                const mailData = {
                    email: remail.current.value,
                };
                window.localStorage.removeItem("iphephile");
                setIslogin(true);

                const { data } = await Axios.post("/re_send", mailData)
                    .then(async (res) => {
                        // console.log(res,"res")
                        if (res?.data?.success) {
                            setVerify(true)
                            await setRegmail(localStorage.getItem("regMail"));

                            toast.success(
                                `Email send to you mail,Please Verify Your ${remail.current.value}`,
                                {
                                    duration: 1000, // Set the duration for which the toast is visible
                                }
                            );

                            setremailerr("");

                            setIslogin(false);
                        } else {
                            setVerify(false)
                            setIslogin(false);

                            // console.log(res,"daa")
                            if (res?.data.message === "User Does Not Exist") {
                                toast.error(res?.data?.message);
                            } else if (res?.data.message === "Email already Verified") {
                                //setIslogin(false)

                                toast.error(res?.data?.message);
                                setTimeout(() => {
                                    navigate('/login')
                                }, 1000);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setVerify(false)
                        if (
                            err?.response?.data?.message === "User Does Not Exist" ||
                            err?.response?.data?.message === "BLOCKED_USER" ||
                            err?.response?.data?.message === "Please Verify Email" ||
                            err?.response?.data?.message === "USER DOES NOT EXIST"
                        ) {
                            toast.error(err?.response?.data?.message);
                        }
                        setIslogin(false);
                    });
            }
        } catch (error) {
            setIslogin(false);

            //   toast.error("Something Went Wrong")
        }
    };

    const resendEmail = async () => {
        try {
            const emailRegex =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!remail.current.value) {
                setremailerr("*Email is Required");
            } else if (!emailRegex.test(remail.current.value)) {
                setremailerr("*Invalid Email");
            } else {
                // console.log("Login")
                const mailData = {
                    email: remail.current.value,
                };
                window.localStorage.removeItem("iphephile");
                setIslogin(true);

                const { data } = await Axios.post("/re_send", mailData)
                    .then(async (res) => {
                        // console.log(res, "res")
                        if (res?.data?.success) {
                            await setRegmail(localStorage.getItem("regMail"));
                            toast.success(
                                `Email send to you mail,Please Verify Your ${remail.current.value}`,
                                {
                                    duration: 1000,
                                }
                            );
                            setOtp(true)
                            setRegComponent(false)
                            setremailerr("");
                            setIslogin(false);
                        }
                        else {
                            setIslogin(false);
                            // console.log(res,"daa")
                            if (res?.data.message === "User Does Not Exist") {
                                toast.error(res?.data?.message);
                                setTimeout(() => {
                                    setOtp(false)
                                    setVerifyEmail(false)
                                    setRegComponent(true)
                                }, 1000);
                            } else if (res?.data.message === "Email already Verified") {
                                toast.error(res?.data?.message);
                                setTimeout(() => {
                                    setOtp(false)
                                    setVerifyEmail(false)
                                    // setRegComponent(true)
                                    navigate('/login')
                                }, 1000);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        // setOtpValue(false)
                        // setLoginComponent(true)
                        if (
                            err?.response?.data?.message === "User Does Not Exist" ||
                            err?.response?.data?.message === "BLOCKED_USER" ||
                            err?.response?.data?.message === "Please Verify Email" ||
                            err?.response?.data?.message === "USER DOES NOT EXIST"
                        ) {
                            toast.error(err?.response?.data?.message);
                            setTimeout(() => {
                                setOtp(false)
                                setVerifyEmail(false)
                                setRegComponent(true)
                            }, 1000);
                        }
                        setIslogin(false);
                    });
            }
        } catch (error) {
            // setLoginComponent(true)
            // setOtpValue(false)
            setIslogin(false);

            //   toast.error("Something Went Wrong")
        }
    };

    const handleOtpChange = (e) => {
        const re = /^[0-9\b]+$/;
        setOtpValue(e.target.value)
        if (e.target.value != '' && !re.test(e.target.value)) {
            setOtpErr("Please Enter Valid OTP")
        }
        else {
            setOtpErr('')
        }

    }

    return (
        <>
            <ToastContainer />
            <div className='registerNew'>
                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid xs={12} sm={12} md={12} lg={10} xl={7} className={`${!mdScreen && 'grd-bcg'} grd2`}>
                        <Grid container style={{ justifyContent: 'center', height: !mdScreen && '100%', alignItems: !mdScreen && 'center', backdropFilter: !mdScreen && 'blur(10px)' }}>
                            <Grid xs={12} sm={9} md={7} lg={6} xl={6}>
                                <div className={!mdScreen ? "dmlogo text-center" : "dmlogo"}>
                                    <img src={dmlogo} alt="dmlogo" />
                                </div>

                                {
                                    verifyEmail && !otp ?
                                        <div className="verifyemail-div">
                                            <div style={{ width: "100%" }}>
                                                <div className="mg-top-10 welcm-desc display-1 cursor" onClick={() => { setVerifyEmail(false); setRegComponent(true) }}>
                                                    <ArrowBackIcon />
                                                    <div className="">Back</div>
                                                </div>
                                                <div className={!mdScreen ? "margin-top text-center" : "margin-top"}>
                                                    <div className="welcm" >
                                                        Resend Verification Mail
                                                    </div>

                                                </div>
                                                <div className="margin-top" style={{ width: '80%' }}>
                                                    <div className="fName">
                                                        Email Address*
                                                    </div>
                                                    <div className={!mdScreen && "text-center"}>
                                                        <TextField
                                                            className='logenew-inp'
                                                            id="outlined-basic"
                                                            placeholder='Enter your email address'
                                                            variant="outlined"
                                                            inputRef={remail}
                                                            onChange={() => {
                                                                setremailerr(null);
                                                            }}
                                                        />
                                                    </div>
                                                    {remailerr && <div className="errorDiv" style={{ textAlign: "center" }}>
                                                        {remailerr}
                                                    </div>}
                                                </div>

                                                <div className="margin-top ">
                                                    <div className="margin-top regis" >
                                                        {islogin === false ? (
                                                            <Button onClick={() => { resendEmail() }}>Resend Mail</Button>
                                                        ) : (
                                                            <Button> Processing ...</Button>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        : <></>
                                }
                                {
                                    verifyEmail ? <></> :
                                        <div className={!mdScreen ? "margin-top text-center" : "margin-top"}>

                                            <div className="welcm">
                                                Welcome to Dreamster  👋
                                            </div>
                                            <div className="mg-top-10 welcm-desc">
                                                Kindly fill in your details below to create an account
                                            </div>
                                            {screen &&
                                                <div className="mg-top-10 welcm-desc display-1 cursor" onClick={handleScreen}>
                                                    {
                                                        !otp && !verifyEmail ?
                                                            <div className='display-1' onClick={() => { handleBack() }}>
                                                                <ArrowBackIcon />
                                                                <div className="">Back</div>
                                                            </div>
                                                            : <></>
                                                    }

                                                </div>
                                            }
                                        </div>}
                                {!screen ? <div className="margin-top">
                                    <div className={`${select === 1 ? 'usr' : 'art'}prf display-2 cursor`}
                                        style={{ margin: !mdScreen && '10px auto', width: !smScreen && '80%' }}

                                        onClick={(() => { handleSelect('user') })}
                                    >
                                        <div className="display-1">
                                            <div className="" >
                                                {select === 1 ? <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                                                    <g clip-path="url(#clip0_4985_4870)">
                                                        <path d="M25.375 0H5.375C2.61358 0 0.375 2.23858 0.375 5V25C0.375 27.7614 2.61358 30 5.375 30H25.375C28.1364 30 30.375 27.7614 30.375 25V5C30.375 2.23858 28.1364 0 25.375 0Z" fill="url(#paint0_linear_4985_4870)" />
                                                        <path d="M19.7422 10.948C20.1729 10.5173 20.8712 10.5173 21.302 10.948C21.7327 11.3788 21.7327 12.0771 21.302 12.5078L14.6843 19.1255C14.2536 19.5562 13.5552 19.5562 13.1245 19.1255L9.44804 15.449C9.01732 15.0183 9.01732 14.3199 9.44804 13.8892C9.87877 13.4585 10.5771 13.4585 11.0078 13.8892L13.9044 16.7858L19.7422 10.948Z" fill="#1C1C1C" />
                                                    </g>
                                                    <defs>
                                                        <linearGradient id="paint0_linear_4985_4870" x1="29.8393" y1="26.9531" x2="-0.647957" y2="24.6072" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#175AFF" />
                                                            <stop offset="1" stop-color="#FC5AFF" />
                                                        </linearGradient>
                                                        <clipPath id="clip0_4985_4870">
                                                            <rect x="0.375" width="30" height="30" rx="15" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                    <g clip-path="url(#clip0_4985_4886)">
                                                        <path d="M25 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V25C0.5 27.4853 2.51472 29.5 5 29.5H25C27.4853 29.5 29.5 27.4853 29.5 25V5C29.5 2.51472 27.4853 0.5 25 0.5Z" fill="#1C1C1C" />
                                                    </g>
                                                    <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="white" />
                                                    <defs>
                                                        <clipPath id="clip0_4985_4886">
                                                            <rect width="30" height="30" rx="15" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>}
                                            </div>
                                            <div className="">
                                                <div className={`${select === 1 ? 'usr' : 'art'}prf-txt`}>
                                                    User Profile
                                                </div>
                                                <ul className={`${select === 1 ? 'use' : 'art'}prf-ul`}>
                                                    <li>
                                                        Free Listen & Buy Songs
                                                    </li>
                                                    <li>
                                                        Join Community
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={`${select === 1 ? 'use' : 'art'}ico`} style={{ padding: !smScreen && "8px 16px" }}>
                                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.125 6.25C10.8509 6.25 12.25 4.85089 12.25 3.125C12.25 1.39911 10.8509 0 9.125 0C7.39911 0 6 1.39911 6 3.125C6 4.85089 7.39911 6.25 9.125 6.25Z" fill={select !== 1 ? "url(#paint0_linear_4985_4897)" : "#fff"} />
                                                <path d="M17.4386 11.5513L13.0636 7.80125C12.837 7.60689 12.5483 7.50003 12.2498 7.5H5.9998C5.70128 7.50003 5.41263 7.60689 5.18605 7.80125L0.811054 11.5513C0.680385 11.6634 0.574285 11.8013 0.499406 11.9563C0.424527 12.1113 0.382483 12.2802 0.375911 12.4522C0.369339 12.6243 0.39838 12.7958 0.461213 12.9561C0.524047 13.1164 0.619318 13.262 0.741054 13.3837L4.1248 16.7675V13.2325L3.46355 12.5712L5.3748 10.9325V18.75C5.3748 19.0815 5.5065 19.3995 5.74092 19.6339C5.97534 19.8683 6.29328 20 6.6248 20H11.6248C11.9563 20 12.2743 19.8683 12.5087 19.6339C12.7431 19.3995 12.8748 19.0815 12.8748 18.75V10.9325L14.7861 12.5712L14.1248 13.2325V16.7675L17.5086 13.3837C17.6303 13.262 17.7256 13.1164 17.7884 12.9561C17.8512 12.7958 17.8803 12.6243 17.8737 12.4522C17.8671 12.2802 17.8251 12.1113 17.7502 11.9563C17.6753 11.8013 17.5692 11.6634 17.4386 11.5513Z" fill={select !== 1 ? "url(#paint0_linear_4985_4897)" : "#fff"} />
                                            </svg>

                                        </div>
                                    </div>
                                    <div className={`${select === 2 ? 'usr' : 'art'}prf display-2 cursor`}
                                        style={{ margin: !mdScreen && '10px auto', width: !smScreen && '80%' }}
                                        onClick={(() => { handleSelect('artist') })}
                                    >
                                        <div className="display-1">
                                            <div className="" >
                                                {select === 2 ? <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                                                    <g clip-path="url(#clip0_4985_4870)">
                                                        <path d="M25.375 0H5.375C2.61358 0 0.375 2.23858 0.375 5V25C0.375 27.7614 2.61358 30 5.375 30H25.375C28.1364 30 30.375 27.7614 30.375 25V5C30.375 2.23858 28.1364 0 25.375 0Z" fill="url(#paint0_linear_4985_4870)" />
                                                        <path d="M19.7422 10.948C20.1729 10.5173 20.8712 10.5173 21.302 10.948C21.7327 11.3788 21.7327 12.0771 21.302 12.5078L14.6843 19.1255C14.2536 19.5562 13.5552 19.5562 13.1245 19.1255L9.44804 15.449C9.01732 15.0183 9.01732 14.3199 9.44804 13.8892C9.87877 13.4585 10.5771 13.4585 11.0078 13.8892L13.9044 16.7858L19.7422 10.948Z" fill="#1C1C1C" />
                                                    </g>
                                                    <defs>
                                                        <linearGradient id="paint0_linear_4985_4870" x1="29.8393" y1="26.9531" x2="-0.647957" y2="24.6072" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#175AFF" />
                                                            <stop offset="1" stop-color="#FC5AFF" />
                                                        </linearGradient>
                                                        <clipPath id="clip0_4985_4870">
                                                            <rect x="0.375" width="30" height="30" rx="15" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                    <g clip-path="url(#clip0_4985_4886)">
                                                        <path d="M25 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V25C0.5 27.4853 2.51472 29.5 5 29.5H25C27.4853 29.5 29.5 27.4853 29.5 25V5C29.5 2.51472 27.4853 0.5 25 0.5Z" fill="#1C1C1C" />
                                                    </g>
                                                    <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="white" />
                                                    <defs>
                                                        <clipPath id="clip0_4985_4886">
                                                            <rect width="30" height="30" rx="15" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>}
                                            </div>
                                            <div className="">
                                                <div className={`${select === 2 ? 'usr' : 'art'}prf-txt`}>
                                                    Artist Profile
                                                </div>
                                                <ul className={`${select === 2 ? 'use' : 'art'}prf-ul`}>
                                                    <li>
                                                        Publish Songs & Earn
                                                    </li>
                                                    <li>
                                                        Owner of Backstage pass & Mint
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={`${select === 2 ? 'use' : 'art'}ico`} style={{ padding: !smScreen && "8px 16px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M17.375 0.625C17.125 0.25 16.75 0 16.25 0H3.75C3.25 0 2.875 0.25 2.625 0.625C0 5.625 0 5.875 0 6.25C0 7.625 1.125 8.75 2.5 8.75V18.75C2.5 19.5 3 20 3.75 20H16.25C17 20 17.5 19.5 17.5 18.75V8.75C18.875 8.75 20 7.625 20 6.25C20 5.875 20 5.625 17.375 0.625ZM12.5 17.5V12.5H7.5V17.5H5V8.375C5.375 8.625 5.75 8.75 6.25 8.75C7 8.75 7.625 8.375 8.125 7.875C8.625 8.375 9.25 8.75 10 8.75C10.75 8.75 11.375 8.375 11.875 7.875C12.375 8.375 13 8.75 13.75 8.75C14.25 8.75 14.625 8.625 15 8.375V17.5H12.5Z" fill={select !== 2 ? "url(#paint0_linear_4985_4897)" : "#fff"} />
                                                <defs>
                                                    <linearGradient id="paint0_linear_4985_4897" x1="19.6429" y1="17.9688" x2="-0.681972" y2="16.4048" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#175AFF" />
                                                        <stop offset="1" stop-color="#FC5AFF" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                        </div>
                                    </div>
                                    <div className="alrdy margin-top">
                                        Already have an account? <span className='cursor' onClick={(() => { navigate('/login') })}>Log in</span>
                                    </div>
                                    {/* <div className="alrdy margin-top">
                                        Don't have verify Email?
                                        <span style={{ marginLeft: '5px' }} className='cursor' onClick={() => { setVerifyEmail(true); setScreen(true); setRegComponent(false) }}>Resend Verification Mail</span>
                                    </div> */}
                                </div> :

                                    otp ? <>
                                        <div className="margin-top" style={{ width: '80%' }}>
                                            <div className="fName">
                                                Enter Otp
                                            </div>
                                            <TextField
                                                className='logenew-inp'
                                                id="outlined-basic"
                                                placeholder='Enter Otp'
                                                value={otpValue}
                                                variant="outlined"
                                                // onChange={(e) => { setOtpErr(''); setOtpValue(e.target.value) }}
                                                onChange={(e) => { handleOtpChange(e) }}
                                            />
                                        </div>
                                        {otperr && <div style={{ textAlign: 'center', color: 'red' }}>{otperr}</div>}

                                        <div className="margin-top ">
                                            <div className="margin-top regis" >
                                                {
                                                    islogin3 ? <Button>Processing...</Button> :
                                                        <Button onClick={() => { handleNewVerify() }}>Submit</Button>}

                                            </div>
                                            <div className="alrdy margin-top">
                                                Back to  <span className='cursor' onClick={() => { setOtp(false); setScreen(false) }}>register</span>
                                            </div>

                                        </div>
                                    </>
                                        :
                                        regComponent ?
                                            <>
                                                <div className="reg-height">
                                                    <div className="margin-top mon-mar" style={{ width: '80%' }}>
                                                        <div className="fName">
                                                            First Name
                                                        </div>
                                                        <TextField
                                                            className='logenew-inp'
                                                            id="outlined-basic"
                                                            type='text'
                                                            placeholder='Enter your name'
                                                            variant="outlined"
                                                            inputRef={fname}
                                                            onChange={(e) => {
                                                                handlefirstnamechange(e);
                                                            }}
                                                        />
                                                        {fnameerr && <div className="errorDiv" style={{ textAlign: "center" }}>
                                                            {fnameerr}
                                                        </div>}
                                                    </div>

                                                    <div className="margin-top mon-mar" style={{ width: '80%' }}>
                                                        <div className="fName">
                                                            Last Name
                                                        </div>
                                                        <TextField
                                                            className='logenew-inp'
                                                            id="outlined-basic"
                                                            type='text'
                                                            placeholder='Enter your name'
                                                            variant="outlined"
                                                            inputRef={lname}
                                                            onChange={(e) => {
                                                                handlelastnamechange(e);
                                                            }}
                                                        />
                                                        {lnameerr && <div className="errorDiv" style={{ textAlign: "center" }}>
                                                            {lnameerr}
                                                        </div>
                                                        }
                                                    </div>

                                                    <div className="margin-top mon-mar" style={{ width: '80%' }}>
                                                        <div className="fName">
                                                            Email Address*
                                                        </div>
                                                        <TextField
                                                            className='logenew-inp'
                                                            id="outlined-basic"
                                                            placeholder='Enter your email address'
                                                            variant="outlined"
                                                            inputRef={email}
                                                            onChange={() => {
                                                                emailhandleChange();
                                                            }}
                                                        />
                                                        {emailerr && <div className="errorDiv" style={{ textAlign: "center" }}>
                                                            {emailerr}
                                                        </div>
                                                        }
                                                    </div>

                                                    {/* <div className="margin-top" style={{ width: '80%' }}>
                                        <div className="fName">
                                            Select your Skills
                                        </div>
                                        <TextField className='logenew-inp' id="outlined-basic" placeholder='Search skills here...' variant="outlined" />
                                    </div> */}
                                                    <div className="margin-top mon-mar" style={{ width: '80%' }}>
                                                        <div className="fName">
                                                            Password
                                                        </div>
                                                        <div className={!mdScreen && "text-center"}>
                                                            <FormControl variant="outlined" className='logenew-inp'>
                                                                {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                                                <OutlinedInput
                                                                    id="outlined-adornment-password"
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    placeholder='Password'
                                                                    inputRef={inpassref}
                                                                    onChange={() => {
                                                                        handlePasswordChange();
                                                                    }}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                                onMouseDown={handleMouseDownPassword}
                                                                                edge="end"
                                                                            >
                                                                                {showPassword ? <Visibility sx={{ fill: '#49475A' }} /> : <VisibilityOff sx={{ fill: '#49475A' }} />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                // label="Password"
                                                                />
                                                            </FormControl>
                                                            {passworderr && <div className="errorDiv" style={{ textAlign: "center" }}>
                                                                {passworderr}
                                                            </div>
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="margin-top mon-mar" style={{ width: '80%' }}>
                                                        <div className="fName">
                                                            Confirm Password
                                                        </div>
                                                        <div className={!mdScreen && "text-center"}>
                                                            <FormControl variant="outlined" className='logenew-inp'>
                                                                {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                                                <OutlinedInput
                                                                    id="outlined-adornment-password"
                                                                    type={showPassword1 ? 'text' : 'password'}
                                                                    placeholder='Password'
                                                                    inputRef={inputconfrimRef}
                                                                    onChange={() => {
                                                                        handleConfirmPasswordChange();
                                                                    }}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword1}
                                                                                onMouseDown={handleMouseDownPassword}
                                                                                edge="end"
                                                                            >
                                                                                {showPassword1 ? <Visibility sx={{ fill: '#49475A' }} /> : <VisibilityOff sx={{ fill: '#49475A' }} />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                // label="Password"
                                                                />
                                                            </FormControl>
                                                            {cpassworderr && <div className="errorDiv" style={{ textAlign: "center" }}>
                                                                {cpassworderr}
                                                            </div>
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="margin-top mon-mar ">
                                                        <div className="display-1 ">
                                                            <div onClick={handleChk}>
                                                                {chk ? <div className="display-1"><svg width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0805664 2.33008C0.0805664 1.30533 0.911288 0.474609 1.93604 0.474609H10.5949C11.6196 0.474609 12.4504 1.30533 12.4504 2.33008V10.9889C12.4504 12.0137 11.6196 12.8444 10.5949 12.8444H1.93604C0.911288 12.8444 0.0805664 12.0137 0.0805664 10.9889V2.33008Z" fill="url(#paint0_linear_5006_2191)" />
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.30501 4.36674C9.54655 4.60828 9.54655 4.99989 9.30501 5.24142L5.46598 9.2616C5.22445 9.50314 4.83284 9.50314 4.59131 9.2616L2.73584 7.40614C2.4943 7.1646 2.4943 6.77299 2.73584 6.53146C2.97737 6.28992 3.36898 6.28992 3.61052 6.53146L5.02865 7.94959L8.43034 4.36674C8.67187 4.12521 9.06348 4.12521 9.30501 4.36674Z" fill="white" />
                                                                    <defs>
                                                                        <linearGradient id="paint0_linear_5006_2191" x1="12.2295" y1="11.5881" x2="-0.341226" y2="10.6208" gradientUnits="userSpaceOnUse">
                                                                            <stop stop-color="#175AFF" />
                                                                            <stop offset="1" stop-color="#FC5AFF" />
                                                                        </linearGradient>
                                                                    </defs>
                                                                </svg>
                                                                </div>
                                                                    : <div className="bdr cursor">

                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="trms" >
                                                                I agree to terms & conditions
                                                            </div>

                                                        </div>
                                                        {agreeError && <div className="errorDiv" style={{ textAlign: "center" }}>
                                                            {agreeError}
                                                        </div>}
                                                        <div className="text-center margin-top">
                                                            <ReCAPTCHA
                                                                sitekey={consts.sitekey}
                                                                ref={captchaRef}
                                                                onChange={() => {
                                                                    handlecheckBox();
                                                                }}
                                                            />
                                                            {/* <p>This site is protected by reCAPTCHA and the Google. <span>Privacy Policy</span> and <span>Terms of Service</span> apply.</p> */}
                                                        </div>
                                                        {checkBoxError ? (
                                                            <div className="errorDiv" style={{ textAlign: "center" }}>
                                                                {checkBoxError}
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <div className="margin-top regis" >
                                                            <Button
                                                                // onClick={handleOtp}
                                                                onClick={() => { register() }}
                                                            >Register Account</Button>
                                                        </div>

                                                        <div className="margin-top">
                                                            <div className="display-3">
                                                                <svg width="172" height="2" viewBox="0 0 172 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <line x1="0.84375" y1="0.952148" x2="171.547" y2="0.952163" stroke="#686677" stroke-width="0.742188" />
                                                                </svg>
                                                                <div className="ro">
                                                                    Or
                                                                </div>
                                                                <svg width="172" height="2" viewBox="0 0 172 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <line x1="0.84375" y1="0.952148" x2="171.547" y2="0.952163" stroke="#686677" stroke-width="0.742188" />
                                                                </svg>

                                                            </div>
                                                        </div>

                                                        <div className="margin-top regis-g" >
                                                            {islogin2 === false ? (
                                                                <Button onClick={() => { signinwithGoogle() }}>
                                                                    <div className="display-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                                            <path d="M16.1799 8.21732H15.582V8.18652H8.90234V11.1553H13.0968C12.4849 12.8835 10.8406 14.124 8.90234 14.124C6.44311 14.124 4.44922 12.1301 4.44922 9.6709C4.44922 7.21166 6.44311 5.21777 8.90234 5.21777C10.0375 5.21777 11.0703 5.64602 11.8566 6.34553L13.9559 4.24625C12.6304 3.01088 10.8573 2.24902 8.90234 2.24902C4.80361 2.24902 1.48047 5.57217 1.48047 9.6709C1.48047 13.7696 4.80361 17.0928 8.90234 17.0928C13.0011 17.0928 16.3242 13.7696 16.3242 9.6709C16.3242 9.17326 16.273 8.6875 16.1799 8.21732Z" fill="#FFC107" />
                                                                            <path d="M2.33643 6.21639L4.77488 8.00469C5.43469 6.37113 7.03262 5.21777 8.90256 5.21777C10.0377 5.21777 11.0705 5.64602 11.8568 6.34553L13.9561 4.24625C12.6306 3.01088 10.8575 2.24902 8.90256 2.24902C6.05182 2.24902 3.57959 3.85846 2.33643 6.21639Z" fill="#FF3D00" />
                                                                            <path d="M8.90225 17.0932C10.8193 17.0932 12.5612 16.3596 13.8782 15.1665L11.5812 13.2227C10.811 13.8085 9.86986 14.1253 8.90225 14.1245C6.97182 14.1245 5.3327 12.8936 4.7152 11.1758L2.29492 13.0405C3.52324 15.4441 6.01773 17.0932 8.90225 17.0932Z" fill="#4CAF50" />
                                                                            <path d="M16.1799 8.21732H15.582V8.18652H8.90234V11.1553H13.0968C12.8041 11.9778 12.2768 12.6965 11.5802 13.2226L11.5813 13.2219L13.8783 15.1657C13.7158 15.3134 16.3242 13.3818 16.3242 9.6709C16.3242 9.17326 16.273 8.6875 16.1799 8.21732Z" fill="#1976D2" />
                                                                        </svg>
                                                                        <div className="">
                                                                            Register with Google
                                                                        </div>
                                                                    </div>
                                                                </Button>
                                                            ) : (
                                                                <Button>Processing ...</Button>
                                                            )}

                                                        </div>


                                                        <div className="alrdy margin-top">
                                                            Already have an account? <span className='cursor' onClick={(() => { navigate('/login') })}>Log in</span>
                                                        </div>

                                                        <div className="alrdy margin-top">
                                                            Don't have verify Email?
                                                            <span style={{ marginLeft: '5px' }} className='cursor' onClick={() => { setVerifyEmail(true); setScreen(true); setRegComponent(false) }}>Resend Verification Mail</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                }


                            </Grid>
                            {lgScreen && <Grid xs={12} sm={12} md={5} lg={6} xl={6}>

                                <div className="lognew">
                                    <img src={lognew} alt="lognew" style={{ width: !lgScreen && '100%' }} />
                                </div>

                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </div >
        </>
    )
}

export default RegisterNew
