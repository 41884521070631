import React, { useState } from 'react'
import SidebarNew from '../SidebarNew/SidebarNew'
import { Avatar, Box, Grid } from '@mui/material'
import './Genre.css'
import { Button, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import card from '../../img/dster-webp/card-4.webp'
import card2 from '../../img/dster-webp/fire.webp'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DynamicCut from '../PlayListNew/DynamicCut';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'var(--clr-bcg)',
    borderRadius: '12px',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const drawerWidth = 300;

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];


function Genre() {

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const [name, setName] = React.useState();

    const mobileUp = useMediaQuery(theme.breakpoints.up('sm'));

    const navigate = useNavigate()
    const [anchorEl2, setAnchorEl2] = useState(null);

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose3 = () => {
        setAnchorEl2(null);
    };

    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover' : undefined;

    const [playlists, setPlaylists] = useState([
        { id: 1, name: 'Playlist #1', creator: 'First Name' }
    ]);

    // Function to add a new playlist
    const addPlaylist = () => {
        const newPlaylist = {
            id: playlists.length + 1, // Simple ID generation
            name: `Playlist #${playlists.length + 1}`,
            creator: 'New Creator' // You can modify this to take user input
        };
        setPlaylists([...playlists, newPlaylist]);
        // handleClose()
    };



    const [folder, setFolder] = useState([
        { id: 1, name: 'Playlist #1', creator: 'First Name' }
    ]);

    // Function to add a new playlist
    const addFolder = () => {
        const newFolder = {
            id: folder.length + 1, // Simple ID generation
            name: `Playlist #${folder.length + 1}`,
            creator: 'New Creator' // You can modify this to take user input
        };
        setFolder([...folder, newFolder]);
        // handleClose()
    };


    const [inFolder, setInFolder,] = useState(false)

    const handleInFolder = () => {
        setInFolder(!inFolder)

    }


    const [model, setModel] = useState(false);
    const handleOpen = () => setModel(true);
    const handleClose2 = () => setModel(false);

    const [imageUrl, setImageUrl] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        console.log(reader, "result of file");

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleRightClick = (event) => {
        event.preventDefault();
        // Your function logic here
        setAnchorEl2(event.currentTarget);
        console.log('Right click event triggered');
    };
    const [playCard, setPlaycard] = useState([
        { img: 'https://imgs.search.brave.com/gTGcFA5drHatTMbjYOP3X-CROolnU7u_bxbi4rdlrWg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9y/ZWxheGVkLWFmcmlj/YW4tZ2lybC13aXRo/LWJpZy1leWVzLXNt/aWxpbmctYXBwZWFs/aW5nLWxhZHktbGlz/dGVuaW5nLW11c2lj/LXdoaXRlXzE5NzUz/MS0xMTI4MS5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw' },
        { img: "https://imgs.search.brave.com/HvqPR3gn8GFFQ9J8-sokBi0jvSJWz364dV9mPZGoUyc/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9hZnJpY2FuLXdv/bWFuLXNpbmdpbmct/d2hpbGUtbGlzdGVu/aW5nLW11c2ljLWhl/YWRwaG9uZXMtaXNv/bGF0ZWQtZ3JleS1i/YWNrZ3JvdW5kLWNv/cHktc3BhY2VfNjEz/NjUyLTQ0MC5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw" },
        { img: 'https://imgs.search.brave.com/krYFxbma7Nk_tDb7WxmAkBP-Yq03lI-oc7LR-gmBr_Q/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9w/bGVhc2VkLWdvb2Qt/bG9va2luZy1naXJs/LXdpdGgtY3VybHkt/YWZyby1oYWlyLWNs/b3Nlcy1leWVzLWVt/YnJhY2VzLWhlcnNl/bGYtbGlzdGVucy1t/dXNpY18yNzM2MDkt/NDU3NDYuanBnP3Np/emU9NjI2JmV4dD1q/cGc' },
        // { img: "https://imgs.search.brave.com/krx_xPUZGcu2ldKw1qreusemorEtlIMuaqCo4SPdnZY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by93/aW5zb21lLWNhcmVm/cmVlLWJsYWNrLWdp/cmwtbGlzdGVuaW5n/LW11c2ljLXN0dWRp/by1zaG90LWpvY3Vu/ZC1hZnJpY2FuLWZl/bWFsZS1tb2RlbC1w/b3NpbmctaGVhZHBo/b25lc18xOTc1MzEt/MTExNTkuanBnP3Np/emU9NjI2JmV4dD1q/cGc" }
    ])

    const { enqueueSnackbar } = useSnackbar();



    const handleClickVariant = (variant) => () => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar('This feature is under development!', { variant });
    };
    return (
        <div className='Genre'>

            <Box sx={{ display: mobileUp ? 'flex' : 'block' }}>
                <SidebarNew name={name} setName={setName} />
                <Box
                    component="main"
                    // className="explore-box"
                    sx={{
                        flexGrow: 1,
                        py: 1,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        marginTop: "80px",
                        // marginBottom: "100px",
                    }}
                >

                    <Grid container spacing={0} >
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={9}
                            sx={{ padding: '15px' }}
                        >

                            <Stack direction="row" spacing={1}>
                                <div className='arrowbck'>
                                    <ArrowBackIosNewIcon onClick={(() => { navigate(-1) })} />
                                </div>
                                <div className='arrowbck'>
                                    <ArrowForwardIosIcon onClick={(() => { navigate(+1) })} />
                                </div>

                            </Stack>
                            <div className="display-2 margin-top genre-cov" >
                                <div className="display-1 con-cov">
                                    <div className="genr-img">
                                        <img src={card2} alt="ad" />
                                    </div>
                                    <div className="firetex">
                                        Fire (Official UEFA EURO 2024 Songs)
                                        <div className="display-1 margin-top justc-medi">
                                            <Stack direction="row" spacing={-0.5}>
                                                {playCard.map((row, i) => (
                                                    <Avatar
                                                        key={i}
                                                        alt="Chat Member"
                                                        src={row.img}
                                                        sx={{ width: !mobileUp ? 25 : 35, height: !mobileUp ? 25 : 35 }}
                                                    />
                                                ))}
                                            </Stack>
                                            <div className="meduza">MEDUZA, OneRepublic, Leony</div>
                                        </div>
                                        <Stack direction="row" className='margin-top trc1' spacing={1} sx={{ background: 'linear-gradient(93.51deg, #B5179E -13.04%, #7209B7 124.22%)', padding: '8px', width: 'fit-content', alignItems: 'center', borderRadius: '5px' }}>
                                            <div className='tkdet'>
                                                1 track
                                            </div>

                                            <div className="tkdet"> 02 minutes</div>

                                            <div className="tkdet">05/10/2024</div>

                                            <div className="tkdet">593 fans</div>
                                        </Stack>
                                        <Stack direction="row" className='margin-top' spacing={4} sx={{ alignItems: 'center' }}>
                                            <div className="albPlu cursor">
                                                <PlayArrowIcon sx={{ fill: '#fff' }} />
                                            </div>
                                            <div className="svgsz">
                                                <AddCircleOutlineIcon onClick={handleClickVariant('warning')} />
                                            </div>
                                            <div className="svgsz">
                                                <MoreHorizIcon onClick={handleClick2} />
                                            </div>
                                        </Stack>
                                    </div>
                                </div>

                                {/* <Stack direction="row" spacing={4}>
                                    <FavoriteIcon />
                                    <BookmarkIcon />
                                </Stack> */}
                            </div>
                            {/* <div className="display-2 mg-top-30">
                                <Stack direction="row" spacing={4} sx={{ alignItems: 'center' }}>
                                    <div className="albPlu">
                                        <PlayArrowIcon />
                                    </div>
                                    <div className="svgsz">
                                        <AddCircleOutlineIcon />
                                    </div>
                                    <div className="svgsz">
                                        <MoreHorizIcon onClick={handleClick2} />
                                    </div>
                                </Stack>

                                <Stack direction="row" spacing={4} sx={{ alignItems: 'center' }}>
                                    <div className="frstname" style={{ fontSize: '18px' }}>
                                        List
                                    </div>
                                    <div className="svgsz">
                                        <FormatListBulletedIcon />
                                    </div>
                                </Stack>
                            </div> */}
                            {/* <hr style={{ borderColor: '#ffffff5c' }} /> */}
                            {/* <div className="concet-tex">
                                Concerts
                            </div>
                            <div className="display-1 cont-mn">
                                <div className="conet">
                                    <img src={card} alt="dad" />
                                </div>
                                <div className="ontour">
                                    On Tour
                                    <div className="ontour-tx">
                                        Toper 2
                                    </div>
                                    <div className="seeall">
                                        <Button>
                                            See all events
                                        </Button>
                                    </div>
                                </div>
                            </div> */}

                            <TableContainer className='tablecont-playlist '>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Title</TableCell>
                                            <TableCell align="center">Date Added</TableCell>
                                            <TableCell align="center">Most Listen</TableCell>
                                            <TableCell align="center">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_4416_2693)">
                                                        <path d="M11.7841 20.5331C16.6258 20.5331 20.5507 16.6082 20.5507 11.7666C20.5507 6.92493 16.6258 3 11.7841 3C6.9425 3 3.01758 6.92493 3.01758 11.7666C3.01758 16.6082 6.9425 20.5331 11.7841 20.5331Z" stroke="white" stroke-width="1.40265" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M11.6895 7.16016V12.274H16.8033" stroke="white" stroke-width="1.40265" stroke-linecap="round" stroke-linejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_4416_2693">
                                                            <rect width="23.3775" height="23.3775" fill="white" transform="translate(0 0.585938)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {['', '', '', '', ''].map((row, i) => {
                                            return (
                                                <TableRow
                                                    // key={row.name}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 }, borderRadius: '10px',
                                                        overflow: 'hidden',
                                                    }}
                                                    onContextMenu={handleRightClick}
                                                    className='alb cursor'
                                                >
                                                    <TableCell align="center">{i + 1}</TableCell>
                                                    <TableCell align="center">
                                                        <div className="display-1">
                                                            <div className="tb-img">
                                                                <img src={card} alt="" />
                                                            </div>
                                                            <div className="wecant">
                                                                We can't be friends
                                                                <div className="inge-nmae">
                                                                    Singer Name
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <div className="dtead">
                                                            22/01/2024, at 06.30pm
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <div className="dtead">
                                                            1M listened
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <div className="dtead">
                                                            3:39
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <div className="mnt">
                                                            <Button onClick={(() => { navigate('/mintnew') })}>
                                                                {/* Mint */} Buy
                                                            </Button>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid xs={12} sx={12} md={12} lg={12} xl={3}>
                            <DynamicCut />
                        </Grid>
                    </Grid>
                    {/* <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}

                    >
                        <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>
                            <div className=' display-1 cursor' onClick={addPlaylist}>
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.66602 1.66699H18.3327V10.8337H16.666V3.33366H3.33268V16.667H10.8327V18.3337H1.66602V1.66699ZM9.99935 5.83366H13.3327V7.50033H11.666V11.667C11.6657 12.1976 11.4966 12.7144 11.1832 13.1426C10.8697 13.5707 10.4281 13.888 9.92234 14.0485C9.41657 14.2091 8.87284 14.2045 8.36984 14.0355C7.86683 13.8665 7.43064 13.5419 7.1244 13.1085C6.81815 12.6752 6.65773 12.1556 6.66635 11.6251C6.67497 11.0945 6.85218 10.5804 7.17234 10.1573C7.49251 9.73412 7.93901 9.4238 8.44724 9.27124C8.95547 9.11867 9.49906 9.13178 9.99935 9.30866V5.83366ZM9.99935 11.667C9.99935 11.446 9.91155 11.234 9.75527 11.0777C9.59899 10.9215 9.38703 10.8337 9.16602 10.8337C8.945 10.8337 8.73304 10.9215 8.57676 11.0777C8.42048 11.234 8.33268 11.446 8.33268 11.667C8.33268 11.888 8.42048 12.1 8.57676 12.2562C8.73304 12.4125 8.945 12.5003 9.16602 12.5003C9.38703 12.5003 9.59899 12.4125 9.75527 12.2562C9.91155 12.1 9.99935 11.888 9.99935 11.667ZM16.666 12.5003V15.0003H19.166V16.667H16.666V19.167H14.9993V16.667H12.4993V15.0003H14.9993V12.5003H16.666Z" fill="#636363" />
                                    </svg>

                                </div>
                                <div>
                                    <div className='frstname' >
                                        Create PlayList
                                    </div>

                                </div>

                            </div>
                            <div className=' display-1 cursor' onClick={addFolder} >
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.64102 4.99967L9.30768 6.66634H16.666V14.9997H3.33268V4.99967H7.64102ZM8.33268 3.33301H3.33268C2.41602 3.33301 1.67435 4.08301 1.67435 4.99967L1.66602 14.9997C1.66602 15.9163 2.41602 16.6663 3.33268 16.6663H16.666C17.5827 16.6663 18.3327 15.9163 18.3327 14.9997V6.66634C18.3327 5.74967 17.5827 4.99967 16.666 4.99967H9.99935L8.33268 3.33301Z" fill="#636363" />
                                    </svg>
                                </div>
                                <div>
                                    <div className='frstname' >
                                        Create a playlist folder
                                    </div>

                                </div>

                            </div>
                        </div>
                    </Popover> */}
                    <Popover
                        id={id2}
                        open={open2}
                        anchorEl={anchorEl2}
                        onClose={handleClose3}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                    >
                        <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>
                            <div className=' display-1 cursor' >
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_4446_2782)">
                                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4446_2782">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div>
                                    <div className='frstname' >
                                        Remove from profile
                                    </div>

                                </div>

                            </div>

                            <hr style={{ borderColor: '#ffffff5c' }} />
                            <div className=' display-1 cursor' >
                                <div className=''>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 15.8751H1.75C1.58424 15.8751 1.42527 15.8093 1.30806 15.692C1.19085 15.5748 1.125 15.4159 1.125 15.2501V11.7587C1.12508 11.5932 1.19082 11.4344 1.30781 11.3173L10.9422 1.68291C11.0594 1.56579 11.2183 1.5 11.384 1.5C11.5497 1.5 11.7086 1.56579 11.8258 1.68291L15.3172 5.17198C15.4343 5.28917 15.5001 5.44808 15.5001 5.61377C15.5001 5.77946 15.4343 5.93837 15.3172 6.05557L5.5 15.8751Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M14.875 15.875H5.5" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M8.625 4L13 8.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>



                                </div>
                                <div>
                                    <div className='frstname' >
                                        Edit details
                                    </div>

                                </div>

                            </div>
                            <div className=' display-1 cursor' >
                                <div className=''>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.875 3.375H1.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.125 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9.875 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.625 3.375V15.25C13.625 15.4158 13.5592 15.5747 13.4419 15.6919C13.3247 15.8092 13.1658 15.875 13 15.875H3C2.83424 15.875 2.67527 15.8092 2.55806 15.6919C2.44085 15.5747 2.375 15.4158 2.375 15.25V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.125 3.375V2.125C11.125 1.79348 10.9933 1.47554 10.7589 1.24112C10.5245 1.0067 10.2065 0.875 9.875 0.875H6.125C5.79348 0.875 5.47554 1.0067 5.24112 1.24112C5.0067 1.47554 4.875 1.79348 4.875 2.125V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>


                                </div>
                                <div>
                                    <div className='frstname' >
                                        Delete
                                    </div>

                                </div>

                            </div>
                            <hr style={{ borderColor: '#ffffff5c' }} />
                            <div className=' display-1 cursor' onClick={addPlaylist}>
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.66602 1.66699H18.3327V10.8337H16.666V3.33366H3.33268V16.667H10.8327V18.3337H1.66602V1.66699ZM9.99935 5.83366H13.3327V7.50033H11.666V11.667C11.6657 12.1976 11.4966 12.7144 11.1832 13.1426C10.8697 13.5707 10.4281 13.888 9.92234 14.0485C9.41657 14.2091 8.87284 14.2045 8.36984 14.0355C7.86683 13.8665 7.43064 13.5419 7.1244 13.1085C6.81815 12.6752 6.65773 12.1556 6.66635 11.6251C6.67497 11.0945 6.85218 10.5804 7.17234 10.1573C7.49251 9.73412 7.93901 9.4238 8.44724 9.27124C8.95547 9.11867 9.49906 9.13178 9.99935 9.30866V5.83366ZM9.99935 11.667C9.99935 11.446 9.91155 11.234 9.75527 11.0777C9.59899 10.9215 9.38703 10.8337 9.16602 10.8337C8.945 10.8337 8.73304 10.9215 8.57676 11.0777C8.42048 11.234 8.33268 11.446 8.33268 11.667C8.33268 11.888 8.42048 12.1 8.57676 12.2562C8.73304 12.4125 8.945 12.5003 9.16602 12.5003C9.38703 12.5003 9.59899 12.4125 9.75527 12.2562C9.91155 12.1 9.99935 11.888 9.99935 11.667ZM16.666 12.5003V15.0003H19.166V16.667H16.666V19.167H14.9993V16.667H12.4993V15.0003H14.9993V12.5003H16.666Z" fill="#636363" />
                                    </svg>

                                </div>
                                <div>
                                    <div className='frstname' >
                                        Create PlayList
                                    </div>

                                </div>

                            </div>
                            <div className=' display-1 cursor' onClick={addFolder} >
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_4446_2802)">
                                            <path d="M3.125 10H16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10 3.125V16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4446_2802">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </div>
                                <div>
                                    <div className='frstname' >
                                        Create a Playlist folder
                                    </div>

                                </div>

                            </div>
                            <div className=' display-1 cursor' >
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8227 10.3539L12.6275 12.1587L12.1596 12.6266L10.3549 10.8218L10.0013 10.4682L9.64775 10.8218L7.84297 12.6266L7.37508 12.1587L9.17986 10.3539L9.53341 10.0003L9.17986 9.64677L7.37508 7.84199L7.84297 7.3741L9.64775 9.17888L10.0013 9.53243L10.3549 9.17888L12.1596 7.3741L12.6275 7.84199L10.8227 9.64677L10.4692 10.0003L10.8227 10.3539ZM2.83464 10.0003C2.83464 13.9515 6.05016 17.167 10.0013 17.167C13.9524 17.167 17.168 13.9515 17.168 10.0003C17.168 6.04918 13.9524 2.83366 10.0013 2.83366C6.05016 2.83366 2.83464 6.04918 2.83464 10.0003ZM2.16797 10.0003C2.16797 5.66813 5.66911 2.16699 10.0013 2.16699C14.3335 2.16699 17.8346 5.66813 17.8346 10.0003C17.8346 14.3325 14.3335 17.8337 10.0013 17.8337C5.66911 17.8337 2.16797 14.3325 2.16797 10.0003Z" stroke="#636363" />
                                    </svg>


                                </div>
                                <div>
                                    <div className='frstname' >
                                        Exclude from your taste profile
                                    </div>

                                </div>

                            </div>
                            <div className=' display-1 cursor'  >
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_4446_2807)">
                                            <path d="M17.5 6.875V15.6945C17.5 15.8419 17.4415 15.9831 17.3373 16.0873C17.2331 16.1915 17.0919 16.25 16.9445 16.25H3.125C2.95924 16.25 2.80027 16.1842 2.68306 16.0669C2.56585 15.9497 2.5 15.7908 2.5 15.625V5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H7.29141C7.42664 4.375 7.55822 4.41886 7.66641 4.5L10 6.25H16.875C17.0408 6.25 17.1997 6.31585 17.3169 6.43306C17.4342 6.55027 17.5 6.70924 17.5 6.875Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4446_2807">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div>
                                    <div className='frstname' >
                                        Move to folder
                                    </div>

                                </div>

                            </div>
                            <div className=' display-1 cursor' >
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_4446_2811)">
                                            <path d="M17.9422 6.68291C18.0594 6.56571 18.1252 6.40681 18.1252 6.24112C18.1252 6.07543 18.0594 5.91652 17.9422 5.79932L13.2032 1.05791C13.086 0.940792 12.9271 0.875 12.7614 0.875C12.5957 0.875 12.4368 0.940792 12.3196 1.05791L7.84303 5.54698C7.84303 5.54698 5.67506 4.46338 3.35943 6.33213C3.29077 6.3871 3.23447 6.45593 3.19424 6.53415C3.154 6.61236 3.13072 6.69818 3.12593 6.78601C3.12114 6.87383 3.13493 6.96168 3.16642 7.04381C3.19791 7.12593 3.24637 7.20049 3.30865 7.2626L11.7383 15.6915C11.8015 15.7542 11.8773 15.8027 11.9606 15.8337C12.044 15.8647 12.133 15.8776 12.2218 15.8715C12.3105 15.8653 12.3969 15.8403 12.4752 15.7981C12.5535 15.7559 12.6219 15.6974 12.6758 15.6267C13.3313 14.7548 14.361 12.9634 13.4657 11.1735L17.9422 6.68291Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.52266 11.4775L3.75 15.2502" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4446_2811">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </div>
                                <div>
                                    <div className='frstname' >
                                        Pin playlist
                                    </div>

                                </div>

                            </div>
                            <hr style={{ borderColor: '#ffffff5c' }} />
                            <div className=' display-1 cursor' >
                                <div className=''>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_4446_2816)">
                                            <path d="M2.41876 14.525C3.70469 13.1555 7.08048 10.25 11.8773 10.25V14L18.1273 7.75L11.8773 1.5V5.25C7.75235 5.25 2.46407 9.19141 1.87735 14.2766C1.86923 14.3424 1.88228 14.4091 1.9146 14.4671C1.94693 14.525 1.99686 14.5712 2.05717 14.5988C2.11747 14.6265 2.18502 14.6343 2.25003 14.621C2.31504 14.6077 2.37413 14.5741 2.41876 14.525Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4446_2816">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>


                                </div>
                                <div>
                                    <div className='frstname' >
                                        Share
                                    </div>

                                </div>

                            </div>
                        </div>
                    </Popover>
                    {/* <Modal
                        open={model}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="modal-style"
                    >
                        <Box sx={style}>
                            <div className="display-2">
                                <div className="edtdet">
                                    Edit details
                                </div>
                                <div className="cls" onClick={handleClose2}>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.0625 0.9375L0.9375 9.0625M0.9375 0.9375L9.0625 9.0625" stroke="#4C4C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>

                            </div>
                            <div className="margin-top display-1">

                                <Button
                                    className='lbk'
                                    component="label"
                                    role={undefined}
                                    // variant="contained"
                                    tabIndex={-1}
                                // startIcon={<CloudUploadIcon />}
                                >
                                    <div className="palylistimg cursor">
                                        <img src={imageUrl ? imageUrl : playlist} alt="playlist" />
                                    </div>
                                    <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                                    <div className='chsimg'>
                                        Choose Image
                                    </div>
                                </Button>
                                <div className="mdoel-inpu">
                                    <TextField id="outlined-basic" placeholder="Name" variant="outlined" />
                                    <div className="mdoel-text">
                                        <TextField
                                            id="outlined-multiline-static"
                                            placeholder="Description"
                                            multiline
                                            rows={4}
                                        // defaultValue="Default Value"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sve">
                                <Button>
                                    Save
                                </Button>
                            </div>
                            <div className="by-precee" style={{ color: 'var(--clr-font)' }}>
                                By proceeding, you agree to give Dreamster access to the image you choose to Upload Please make sure you have the right to upload the image.
                            </div>
                        </Box>
                    </Modal> */}
                </Box>
            </Box>
        </div>
    )
}

export default Genre
