import Grid from "@mui/material/Grid";
// import "../Music-player/Player.css";
import "./Cominsoon.css"
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box, Button } from "@mui/material";
// import Sidebar
import { useTheme, useMediaQuery } from '@mui/material';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Link, Navigate, useNavigate } from 'react-router-dom'
import SidebarNew from "../SidebarNew/SidebarNew";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});

const drawerWidth = 300;

function Comingsoon() {
    const classes = useStyles();

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <div className="fanzone" sx={{ bgcolor: '#000' }}>
                <div className="playerBanner"></div>
                <Box sx={{ display: isLgUp ? 'flex' : 'block' }}>
                    <SidebarNew />
                    <Box
                        component="main"
                        // className="explore-box"
                        sx={{
                            flexGrow: 1,
                            py: 1,
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            // height: '125vh',
                            marginTop: "80px",
                            // marginBottom: audio === '' ? '0px' : audio === undefined ? '0px' : '0px',
                        }}
                    >
                        <Grid container spacing={0} className="music-player-grid">

                            {/* <SidebarNew /> */}


                            <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className="music-player-banner-grid"
                            >
                                <Item className={classes.containWidth}>
                                    <div className="fanzone-body">
                                        <h1 className="faq-platform">
                                            We Are Coming, Stay Updated!
                                        </h1>

                                    </div>
                                </Item>
                            </Grid>





                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <Item className={classes.containWidth}>
        <Footer />
    </Item>
</Grid> */}
                        </Grid>
                    </Box>
                </Box>

            </div>
        </>
    );
}

export default Comingsoon;
