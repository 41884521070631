import { Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import fire from '../../img/dster-webp/fire.webp';
import { useTheme, useMediaQuery } from '@mui/material';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import { PlayArrow, Pause, SkipNext, SkipPrevious, } from '@mui/icons-material';
import { Box, Typography, IconButton, Stack, Button } from '@mui/material';
import Slider from '@mui/material/Slider';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useNavigate } from 'react-router-dom';

function MintPrev() {
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMoblie, setIsMobile] = useState(false)

    const navigate = useNavigate();
    const calculateTimeLeft = () => {
        // Parse the custom date format
        const endDate = new Date('August 15, 2024 14:40:00'); // Ensure the format is correct
        const now = new Date();
        const difference = endDate - now;

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const [vol, setVol] = useState()
    const handleVol = (e) => {
        setVol(e.target.value)
        console.log('log', vol);
    }
    return (
        <div className='mintprev'>
            <Grid container>
                <Grid xs={12} sx={12} md={12} lg={12} xl={12}>
                    <div className="">
                        <div className="prev-img-mnt">
                            <img src={fire} alt="fire" />
                        </div>
                    </div>
                    <Box className="player-controls" style={{ justifyContent: !isLgUp ? 'space-evenly' : 'center' }}>
                        {isLgUp || isMoblie ? <IconButton>
                            {isPlaying ? <FastRewindIcon /> : <FastRewindIcon disabled />}
                        </IconButton> : null}
                        {<IconButton>
                            <SkipPrevious />
                        </IconButton>}
                        <IconButton className='pasue-play'>
                            {!isPlaying ? <PlayArrow /> : <Pause
                            // onClick={() => setPlay(!play)} 
                            />}
                        </IconButton>
                        <IconButton>
                            <SkipNext />
                        </IconButton>
                        {isLgUp || isMoblie ? <IconButton>
                            {isPlaying ? <FastForwardIcon /> : <FastForwardIcon disabled />}

                        </IconButton> : null}
                    </Box>
                    {isLgUp || isMoblie ? <Box sx={{ width: "85%", margin: 'auto', display: 'flex', alignItems: 'center', gap: "20px" }}>
                        <Typography variant="body2" color="var(--clr-font)">
                            {/* {formatDuration(currentTime)} */}
                            0:00
                        </Typography>
                        <Slider
                            aria-label="time-indicator"
                            size="small"
                            // value={currentTime}
                            min={0}
                            step={1}
                            // max={duration}
                            // onChange={handleSeek}
                            className='control-timer'

                        />
                        <Typography variant="body2" color="var(--clr-font)">
                            {/* {currentTime === 0 ? '0:00' : formatDuration(duration)} */}
                            4:29
                        </Typography>

                        {/* <audio ref={audioRef} src={audiosource} />
                            <div className='audio-timmer'>
                                <div>
                                    {formatDuration(currentTime)}

                                </div>
                                <div>
                                    {currentTime === 0 ? '0:00' : formatDuration(duration)}

                                </div>
                            </div> */}
                        {/* <audio ref={audioRef} src={props.audiosource} /> */}


                    </Box> : null
                    }
                    <div className="display-1 margin-top innermnt" style={{ margin: '15px auto' }}>
                        <div className="the">
                            0.35 ETH
                            <div className="crrpr">
                                <div className="">
                                    Current Price
                                </div>
                            </div>
                            <div className="mnt margin-top">
                                <Button sx={{ padding: '8px 40px' }} onClick={(() => { navigate('/mintnew') })}>
                                    {/* Mint */} Buy
                                </Button>
                            </div>
                        </div>
                        <div className="">
                            <div className="salesend">
                                Sale ends August 15, 2024 at 2:40 PM
                            </div>
                            <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }} className='margin-top'>

                                <div className='salesend'>
                                    {timeLeft.hours}
                                    <div className="hrs">Hours</div>
                                </div>
                                <div className='salesend'>
                                    {timeLeft.minutes}
                                    <div className="hrs">Minutes</div>
                                </div>
                                <div className='salesend'>
                                    {timeLeft.seconds}
                                    <div className="hrs">Seconds</div>
                                </div>
                            </Stack>
                        </div>
                    </div>

                    <div className="cpyright">Copyrights Disclamer !</div>
                    <div className="cpy-desc">
                        Songtrust administers over 4M songs for more than 445,000 songwriters, businesses, and rights holders around the world. We monitor, register, and collect publishing royalties from 65 global pay sources, providing access to the majority of the world's music market in over 240 countries and territories on behalf of our clients. Songtrust's mission is to build a better music business by educating and empowering music creators to manage their royalties directly without giving up any of their rights.
                    </div>
                    <>
                        {isLgUp || isMoblie ? <Stack direction="row" spacing={3} style={{ justifyContent: !isLgUp ? 'space-evenly' : 'center', margin: '15px 0', alignItems: 'center' }}>
                            <div className="display-1">
                                {vol === 0 ? <VolumeOffIcon /> : vol <= 50 ? <VolumeDownIcon /> : <VolumeUpIcon />}
                                <Slider
                                    size="small"
                                    defaultValue={70}
                                    onChange={handleVol}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                    sx={{ width: isLgUp ? '60px' : '100px' }}
                                    className='control-timer'
                                />

                            </div>
                            <div>
                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.7207 1L18.38 3.65934L15.7207 6.31868" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.7324 3.65918H18.3808" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15.7207 8.97852L18.38 11.6379L15.7207 14.2972" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M1.09473 3.65918H5.74857L11.0673 11.6372H18.3804" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M1.09473 11.6377H5.74857" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </div>
                            {
                                <div >
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.53835 17L3.46143 13.9231L6.53835 10.8462" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.9999 10.2296V12.6911C16.9999 13.0175 16.8702 13.3306 16.6394 13.5614C16.4086 13.7922 16.0955 13.9219 15.7691 13.9219L3.46143 13.9219" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.4616 1.0024L14.5385 4.07933L11.4616 7.15625" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M1 7.76562L1 5.30409C1 4.97767 1.12967 4.66462 1.36048 4.4338C1.5913 4.20299 1.90435 4.07332 2.23077 4.07332L14.5385 4.07332" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            }

                            <div  >
                                <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.6007 17L2.53766 9.63024C-1.84443 5.20839 4.59724 -3.28158 10.6007 3.58704C16.6042 -3.28158 23.0166 5.23786 18.6637 9.63024L10.6007 17Z" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </div>
                            <div>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.34801 13.6521C6.14474 13.6521 7.60129 12.1955 7.60129 10.3988C7.60129 8.60205 6.14474 7.14551 4.34801 7.14551C2.55127 7.14551 1.09473 8.60205 1.09473 10.3988C1.09473 12.1955 2.55127 13.6521 4.34801 13.6521Z" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.638 19.7966C18.4348 19.7966 19.8913 18.3401 19.8913 16.5433C19.8913 14.7466 18.4348 13.29 16.638 13.29C14.8413 13.29 13.3848 14.7466 13.3848 16.5433C13.3848 18.3401 14.8413 19.7966 16.638 19.7966Z" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.638 7.50656C18.4348 7.50656 19.8913 6.05002 19.8913 4.25328C19.8913 2.45654 18.4348 1 16.638 1C14.8413 1 13.3848 2.45654 13.3848 4.25328C13.3848 6.05002 14.8413 7.50656 16.638 7.50656Z" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.25391 8.9525L13.7315 5.69922" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.25391 11.8447L13.7315 15.098" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </div>
                        </Stack> : null}
                    </>

                </Grid>
            </Grid>
        </div>
    )
}

export default MintPrev
