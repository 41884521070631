import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import './PlayListNew.css'
import { Button, useTheme, useMediaQuery, Tabs, Tab } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import playlistimg from '../../img/dster-webp/playlistimg.webp'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import Popover from '@mui/material/Popover';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import Axios from '../../Axios'
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Tooltip from "@mui/material/Tooltip";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'var(--clr-bcg)',
    borderRadius: '12px',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'var(--clr-bcg)',
    borderRadius: '12px',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const drawerWidth = 300;

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


// function DynamicCut({ getplayLists, getOneTrack, getOneAlbum, setPlaylistDetails, PlaylistDetails, getUserFav, songs, tabValue, setTabValue, isAlive, setIsAlive, getUserSaved, name }) {
function DynamicCut(props) {

    const getplayLists = props?.getplayLists
    const getOneTrack = props?.getOneTrack
    const getOneAlbum = props?.getOneAlbum
    const getUserFav = props?.getUserFav
    const getUserSaved = props?.getUserSaved
    const name = props?.name
    const PlaylistDetails = props?.PlaylistDetails
    const setPlaylistDetails = props?.setPlaylistDetails
    const isAlive = props?.isAlive
    const setIsAlive = props?.setIsAlive
    const tabValue = props?.tabValue
    const setTabValue = props?.setTabValue
    const getMusicLibrary = props?.getMusicLibrary
    const getOneSong = props?.getOneSong

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const tokens = localStorage.getItem("iphephile");
    const navigate = useNavigate()
    const location = useLocation()


    const [anchorEl, setAnchorEl] = useState(null);
    const [track, setTrack] = useState([]);
    const [album, setAlbum] = useState([]);
    const [play, setPlay] = useState([]);
    const [playlist, setPlaylist] = useState([]);
    const [otherAlbums, setOtherAlbums] = useState([])

    const [userName, setUserName] = useState()
    const [music_type, setMusic_type] = useState()
    // const [profileData, setProfileData] = useState({});
    const [type, setType] = useState('track')
    const [folderName, setFolderName] = useState('')
    const [folderNameErr, setFolderNameErr] = useState()
    const [music, setMusic] = React.useState([]);
    const [playlistOpen, setPlaylistOpen] = useState(false)
    const [albumOpen, setAlbumOpen] = useState(false)
    const [trackOpen, setTrackOpen] = useState(false)

    const [ID, setId] = useState()
    // console.log(type, "type");


    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
        // handleOpen()
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [anchorEl2, setAnchorEl2] = useState(null);

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose3 = () => {
        setAnchorEl2(null);
    };

    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover' : undefined;

    const [playlists, setPlaylists] = useState([
        // { id: 1, name: 'Playlist #1', creator: 'First Name' }
    ]);

    const [dynamicMore, setDynamicMore] = useState(null);

    const handleClickDynamic = (event) => {
        setDynamicMore(event.currentTarget);
    };

    const handleCloseDynamic = () => {
        setDynamicMore(null);
    };

    const openDynamic = Boolean(dynamicMore);
    const idD = openDynamic ? 'simple-popover' : undefined;

    // Function to add a new playlist
    const addPlaylist = async () => {
        // const newPlaylist = {
        //     id: play.length + 1, // Simple ID generation
        //     name: `Playlist #${play.length + 1}`,
        //     creator: userName // You can modify this to take user input
        // };
        // setPlaylists([...playlists, newPlaylist]);
        // handleClose()

        try {
            if (tokens) {
                const create_data = {
                    playlist: `Playlist #${play.length + 1}`,
                    value: folderId ? 'folder' : "",
                    folderId: folderId,
                    opentoeveryone: true
                }
                await Axios.post("/users/create_playlist", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            toast.success(res?.data?.message)
                            handleClose()
                            if (folderId) {
                                getFolder(folderId)
                                if (!setInFolder) {
                                    setFolderId('')
                                }
                            }
                            else {
                                // setValue(1)
                                setTabValue(1)
                                getMusic()
                                // getAllsongs()
                                getOthersPlaylist()
                                setFolderId('')
                            }
                        }
                        else {
                            // toast.error(res?.data?.message)

                        }
                    }).catch((err) => {
                        // toast.error(err?.response?.data?.message)

                    })
            }
            else {
                toast.error("Login to continue")
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }

        } catch (error) {
            console.log(error, "error");
        }


    };


    const [folder, setFolder] = useState([
        // { id: 1, name: 'Playlist #1', creator: 'First Name' }
    ]);


    // Function to add a new folder
    const addFolder = async () => {
        try {

            // const newFolder = {
            //     id: play.length + 1,
            //     name: `Playlist #${play.length + 1}`,
            //     creator: userName
            // };
            // setFolder([...folder, newFolder]);
            if (tokens) {
                const response = await Axios.post('/users/create_folder', { name: 'New Folder' },
                    {
                        headers: {
                            Authorization: tokens,
                        },
                    })
                if (response?.data?.success) {
                    toast.success(response?.data.message)
                    setValue(0)
                    getFolder()
                    handleClose()
                }
            }
            else {
                toast.error("Login to continue")
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }

        } catch (error) {
            console.log(error, 'err');
        }
    };

    const getFolder = async (id) => {
        try {
            const res = await Axios.post('/users/getFolder', { id: id },
                {
                    headers: {
                        Authorization: tokens,
                    },
                })
            if (res?.data?.success) {
                let arr1 = res?.data?.result?.[0]?.track

                let arr2 = res?.data?.result?.[0]?.album

                let arr3 = res?.data?.result?.[0]?.playlist

                let arr = []
                arr = arr.concat(arr1, arr2, arr3)
                setFolder(res?.data?.result)
                setPlaylists(arr)
            }
        } catch (error) {
            console.log(error, 'err');
        }
    }



    const [inFolder, setInFolder,] = useState(false)
    const [folderId, setFolderId] = useState()



    const handleInFolder = () => {
        setInFolder(true)

    }
    const handleoutFolder = () => {
        setInFolder(false)

    }

    // const [model, setModel] = useState(false);
    // const handleOpen = () => setModel(true);
    // const handleClose2 = () => setModel(false);

    // const [imageUrl, setImageUrl] = useState(null);

    // const handleImageUpload = (event) => {
    //     const file = event.target.files[0];
    //     const reader = new FileReader();

    //     console.log(reader, "result of file");

    //     reader.onloadend = () => {
    //         setImageUrl(reader.result);
    //     };

    //     if (file) {
    //         reader.readAsDataURL(file);
    //     }
    // };

    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setTrackOpen(false)
        setAlbumOpen(false)
        setPlaylistOpen(false)
        if (newValue == 1) {
            props?.setType('playlist')
            setType('playlist')
        }
        else if (newValue == 2) {
            setType('album')
        }
        else if (newValue == 0) {
            setType('track')
        }
        // if (tabValue) {
        //     setTabValue(null)

        // }
        // else {
        //     setValue(newValue);
        // }
    };


    const getMusic = async () => {
        try {
            await Axios.post(
                "/users/get_music_library",
                {
                    name: name,
                    // status: status,
                },
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        setUserName(res?.data?.result?.[0]?.musicList?.[0]?.createUser?.[0]?.name)
                        setTrack(res?.data?.result?.musicList);
                        setAlbum(res?.data?.result?.musicAlbum);
                        setPlaylist(res?.data?.result?.musicPlay);
                        props?.setUsermusic([])
                        setIsAlive(true)
                    } else {
                        setTrack([]);
                        setAlbum([]);
                        setPlaylist([]);
                        props?.setUsermusic([])
                    }
                })
                .catch((err) => {
                    if (err?.response?.data?.message == "Admin Blocked You") {
                        localStorage.clear();
                        navigate('/');
                        toast.error('Admin Blocked You');
                    }
                });
        } catch (error) {
            console.log(error, "getmusic error");
        }
    };




    useEffect(() => {
        if (isAlive == false) {
            getMusic()
            // getAllsongs()
            getFolder()
            getOthersPlaylist()
            getOthersAlbum()

        }
        else if (tokens !== null) {
            getMusic()
            // getAllsongs()
            getFolder()
            getOthersPlaylist()
            getOthersAlbum()
        }


    }, [value, tabValue, isAlive, tokens, name])

    useEffect(() => {
        setValue(tabValue);
    }, [tabValue]);




    const pinList = async () => {
        try {
            const payload = {
                id: PlaylistDetails.id,
                music_type: PlaylistDetails.music_type
            }

            const res = await Axios.post('/users/pinSongs', payload, {
                headers: {
                    Authorization: tokens
                }
            })

            if (res.data.success) {
                toast.success(res.data.message)
                handleClose3()
                getMusic()
                // getAllsongs()
                getOthersPlaylist()
            }
            else {
                toast.error(res.data.message)
                handleClose3()
                getMusic()
                // getAllsongs()
                getOthersPlaylist()
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    // const getProfile = async () => {
    //     try {
    //         await Axios.get(`/profile/getprofile`, {
    //             headers: {
    //                 Authorization: tokens,
    //             },
    //         })
    //             .then((res) => {
    //                 if (res?.data?.success) {
    //                     setProfileData(res?.data?.result);
    //                 } else {
    //                     // toast.error("Can't Fetch Detail");
    //                 }
    //             })
    //             .catch((err) => {
    //                 // console.log(err);
    //             });
    //     } catch (error) {
    //         // console.log(error);
    //     }
    // };


    //delete folder modal
    const [deleteFolderId, setDeleteFolderId] = useState()
    const [model2, setModel2] = useState(false);
    const handleOpen2 = () => { handleClose3(); setModel2(true); }
    const handleClose5 = () => { setModel2(false) };

    const deleteFolder = async (id) => {
        try {
            const payload = {
                id: id
            }
            const response = await Axios.post('/users/deleteFolder', payload, {
                headers: {
                    Authorization: tokens
                }
            })
            if (response.data.success) {
                toast.success(response.data.message)
                handleClose5()
                getFolder()
                getMusic()
                // getAllsongs()
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const deletemovetofolder = async (id, music_type) => {
        try {
            const payload = {
                id: id,
                music_type: music_type,
                folderId: folderId
            }
            const response = await Axios.post('/users/Removemovetofolder', payload, {
                headers: {
                    Authorization: tokens
                }
            })
            if (response?.data?.success) {
                getMusic()
                // getAllsongs()
                getFolder()
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    //edit folder name
    const [nameId, setNameId] = useState()
    const [model3, setModel3] = useState(false);
    const handleOpen3 = () => { handleClose3(); setModel3(true); setFolderName('') }
    const handleClose6 = () => { setModel3(false); setFolderName('') };

    const editFolder = async () => {
        try {
            const payload = {
                id: nameId,
                folder_name: folderName
            }
            if (folderName == '') {
                setFolderNameErr('Please Enter FolderName')
            }
            else {
                const response = await Axios.post('/users/editFolder', payload,
                    {
                        headers: {
                            Authorization: tokens
                        }
                    }
                )
                if (response.data.success) {
                    toast.success(response.data.message)
                    handleClose6()
                    getFolder()
                }
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    // useEffect(() => {
    //     getProfile()
    // }, [tokens])

    const getAllMusic = async () => {
        try {
            // setLoading(true)
            const datas = await Axios.post(
                "/users/get_user_music",
                {
                    // status: tabContent[value],
                    // limit: limit1,
                    name: name,
                },
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        setMusic(res?.data?.result);
                    } else {
                        setMusic([]);
                    }
                })
                .catch((err) => {
                    // setLoading(false)
                    // console.log(err,"err")
                });
        } catch (error) {
            console.log(error, "error");
        }
    };


    // const getAllsongs = async () => {
    //     try {
    //         const response = await Axios.post('/users/allSongs', {}, {
    //             headers: {
    //                 Authorization: tokens
    //             }
    //         })
    //         if (response?.data?.success) {
    //             setIsAlive(true)
    //             setTrack(response?.data?.result)
    //         }
    //         else {
    //             setIsAlive(true)
    //             setTrack([])
    //         }
    //     } catch (error) {
    //         console.log(error, 'err');

    //     }
    // }

    const getOthersPlaylist = async () => {
        try {
            const response = await Axios.post('/users/findOtherPlaylist', {}, {
                headers: {
                    Authorization: tokens
                }
            })
            // console.log(response, 'response');

            if (response.data.success) {
                setIsAlive(true)
                setPlay(response?.data?.result)
            }
            else {
                setIsAlive(true)
                setPlay([])
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const getOthersAlbum = async () => {
        try {
            const response = await Axios.post('/users/findOtherAlbum', {}, {
                headers: {
                    Authorization: tokens
                }
            })
            // console.log(response, 'response');

            if (response.data.success) {
                setIsAlive(true)
                setOtherAlbums(response?.data?.result)
            }
            else {
                setIsAlive(true)
                setOtherAlbums([])
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const handleTrack = () => {
        props?.setType('tracksongs')
        setTrackOpen(!trackOpen)
        getMusic()
    }

    const handleAlbum = () => {
        props?.setType('albumsongs')
        setAlbumOpen(!albumOpen)
        getMusic()
    }

    const handlePlaylist = () => {
        props?.setType('playlistsongs')
        setPlaylistOpen(!playlistOpen)
        getMusic()
    }

    const addLibrary = async (data) => {
        try {
            if (tokens) {
                const create_data = {
                    song_id: data
                }
                // console.log(create_data)

                await Axios.post("/users/add_library", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        // console.log(res, "res")
                        if (res?.data?.success) {
                            toast.success(res?.data?.message)
                            setIsAlive(false)
                            getMusicLibrary()
                        }
                        else {
                            toast.error(res?.data?.message)

                        }
                    }).catch((err) => {
                        console.log(err, "err")
                        toast.error(err?.response?.data?.message)

                    })
            }
            else {
                toast.error('Please Login To Add Songs to Your Library')
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }

        } catch (error) {
            console.log(error, "error");
        }

    }


    const addalbumLibrary = async (data) => {

        try {
            if (tokens) {
                const create_data = {
                    album_id: data
                }
                // console.log(create_data)

                await Axios.post("/users/add_album_to_library", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        // console.log(res, "res")
                        if (res?.data?.success) {
                            toast.success(res?.data?.message)
                            setIsAlive(false)
                            setValue(2)
                        }
                        else {
                            toast.error(res?.data?.message)
                            setIsAlive(false)
                        }
                    }).catch((err) => {
                        console.log(err, "err")
                        toast.error(err?.response?.data?.message)

                    })
            }
            else {
                toast.error('Please Login To Add Songs to Your Library')
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }

        } catch (error) {
            console.log(error, "error");
        }

    }

    // to add playlist to library
    const addplayLibrary = async (data) => {
        try {
            if (tokens) {
                const create_data = {
                    play_id: data,
                };

                await Axios.post("/users/add_playlist_to_library", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            toast.success(res?.data?.message);
                            setIsAlive(false)
                            setValue(1)
                        } else {
                            toast.error(res?.data?.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err, "err")
                        toast.error(err?.response?.data?.message);
                    });
            }
            else {
                toast.error('Please Login To Add Songs to Your Library')
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            }
        } catch (error) {
            // console.log(error, "error");
        }
    };

    return (
        <div className='DynamicCut'>
            <Grid container spacing={0} >

                <Grid xs={12} sx={12} md={12} lg={12} xl={12}>
                    <div className="margin-top display-2 padding-10">
                        <Stack direction="row" spacing={2} className='margin-top'>
                            {/* <div className=''>
                                <ArrowBackIcon />
                            </div> */}
                            <div className='' onClick={(e) => { handleClick(e) }}>
                                <AddIcon />
                            </div>

                        </Stack>
                        <div className='margin-top display-1 cursor' >
                            <div className=''>
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.4528 7.75C23.5199 7.36692 23.5024 6.97379 23.4015 6.59821C23.3005 6.22262 23.1186 5.87369 22.8684 5.57589C22.6183 5.2781 22.306 5.03866 21.9535 4.87439C21.6009 4.71012 21.2167 4.625 20.8278 4.625H7.17281C6.7839 4.625 6.39969 4.71012 6.04717 4.87439C5.69465 5.03866 5.38236 5.2781 5.13221 5.57589C4.88206 5.87369 4.70012 6.22262 4.59917 6.59821C4.49821 6.97379 4.48068 7.36692 4.54781 7.75M20.8753 4.625C20.9103 4.3 20.9291 4.13875 20.9291 4.005C20.9304 3.38712 20.7029 2.79062 20.2904 2.33058C19.8779 1.87054 19.3097 1.57955 18.6953 1.51375C18.5628 1.5 18.4003 1.5 18.0753 1.5H9.92531C9.60031 1.5 9.43656 1.5 9.30406 1.51375C8.68969 1.57955 8.12145 1.87054 7.70896 2.33058C7.29647 2.79062 7.06896 3.38712 7.07031 4.005C7.07031 4.13875 7.08781 4.30125 7.12406 4.625" stroke="#636363" stroke-width="1.5" />
                                    <path d="M17.7504 21.5H10.2504M25.4929 19.9912C25.0554 23.0912 24.8367 24.6425 23.7154 25.5712C22.5942 26.5 20.9404 26.5 17.6317 26.5H10.3692C7.06166 26.5 5.40666 26.5 4.28541 25.5712C3.16416 24.6425 2.94541 23.0925 2.50791 19.9912L1.98041 16.2413C1.42166 12.2863 1.14291 10.31 2.32791 9.02875C3.51291 7.75 5.62291 7.75 9.84041 7.75H18.1604C22.3779 7.75 24.4879 7.75 25.6729 9.03C26.6092 10.0413 26.6317 11.4875 26.3242 14" stroke="#636363" stroke-width="1.5" stroke-linecap="round" />
                                </svg>

                            </div>
                            <div>
                                <div className='frstname' >
                                    Your Library
                                </div>

                            </div>

                        </div>
                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Box >
                            {
                                localStorage.getItem('role') === 'artist' ?
                                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" className='all-t-b'>

                                        <Tab label="My Creations" {...a11yProps(0)} />
                                        <Tab label="Others Playlist" {...a11yProps(1)} />
                                        <Tab label="Others Album" {...a11yProps(2)} />

                                        {/* <Tab label="My Creation" {...a11yProps(0)} />
                                        <Tab label="Playlist" {...a11yProps(1)} />
                                        <Tab label="Album" {...a11yProps(2)} /> */}
                                    </Tabs> :
                                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" className='all-t-b'>
                                        <Tab label="All" {...a11yProps(0)} />
                                        <Tab label="Playlist" {...a11yProps(1)} />
                                        <Tab label="Album" {...a11yProps(2)} />

                                    </Tabs>
                            }

                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            {playlistOpen ?
                                <div className="display-2">
                                    <Stack direction="row" spacing={2} className='margin-top' sx={{ alignItems: 'center' }} >
                                        <div className='display-1'>
                                            <ArrowBackIcon
                                                onClick={() => {
                                                    handlePlaylist(); getMusic(); getUserFav(); setPlaylistDetails({
                                                        ...PlaylistDetails,
                                                        name: "Liked Songs",
                                                        img: '',
                                                        creator: '',
                                                        music_type: "",
                                                        description: ''
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="plsjist">
                                            Playlist
                                        </div>
                                    </Stack>
                                </div> :
                                albumOpen ?
                                    <div className="display-2">
                                        <Stack direction="row" spacing={2} className='margin-top' sx={{ alignItems: 'center' }} >
                                            <div className='display-1'>
                                                <ArrowBackIcon
                                                    onClick={() => {
                                                        handleAlbum(); getMusic(); getUserFav(); setPlaylistDetails({
                                                            ...PlaylistDetails,
                                                            name: "Liked Songs",
                                                            img: '',
                                                            creator: '',
                                                            music_type: "",
                                                            description: ''
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="plsjist">
                                                Album
                                            </div>
                                        </Stack>
                                    </div> :
                                    trackOpen ?
                                        <div className="display-2">
                                            <Stack direction="row" spacing={2} className='margin-top' sx={{ alignItems: 'center' }} >
                                                <div className='display-1'>
                                                    <ArrowBackIcon
                                                        onClick={() => {
                                                            handleTrack(); getMusic(); getUserFav();
                                                            setPlaylistDetails({
                                                                ...PlaylistDetails,
                                                                name: "Liked Songs",
                                                                img: '',
                                                                creator: '',
                                                                music_type: "",
                                                                description: ''
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="plsjist">
                                                    Track
                                                </div>
                                            </Stack>
                                        </div>
                                        : <>
                                            <div className="margin-top"
                                                onClick={() => {
                                                    if (tokens) {
                                                        if (location.pathname !== '/playlist') {
                                                            navigate('/playlist', { state: { data: 'get' } })
                                                        }
                                                        else {
                                                            getUserFav();
                                                            setType('liked');
                                                            setPlaylistDetails({
                                                                ...PlaylistDetails,
                                                                name: "Liked Songs",
                                                                img: '',
                                                                creator: '',
                                                                music_type: "",
                                                                description: ''
                                                            })
                                                        }
                                                    }
                                                    else {
                                                        toast.error("Login to Continue")
                                                        setTimeout(() => {
                                                            navigate('/login')
                                                        }, [2000])
                                                    }
                                                }}
                                            >
                                                <div className="display-1 lib-play cursor">
                                                    <div className="lib-ply">
                                                        <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="53" height="53" rx="5" fill="url(#paint0_linear_4451_6757)" />
                                                            <g clip-path="url(#clip0_4451_6757)">
                                                                <path d="M37.4375 23.9609C37.4375 30.7969 27.3018 36.3301 26.8701 36.5586C26.7563 36.6198 26.6292 36.6518 26.5 36.6518C26.3708 36.6518 26.2436 36.6198 26.1299 36.5586C25.6982 36.3301 15.5625 30.7969 15.5625 23.9609C15.5643 22.3557 16.2028 20.8167 17.3379 19.6816C18.473 18.5465 20.0119 17.9081 21.6172 17.9062C23.6338 17.9062 25.3994 18.7734 26.5 20.2393C27.6006 18.7734 29.3662 17.9062 31.3828 17.9062C32.9881 17.9081 34.527 18.5465 35.6621 19.6816C36.7972 20.8167 37.4357 22.3557 37.4375 23.9609Z" fill="white" />
                                                            </g>
                                                            <defs>
                                                                <linearGradient id="paint0_linear_4451_6757" x1="52.0536" y1="47.6172" x2="-1.80722" y2="43.4727" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="#175AFF" />
                                                                    <stop offset="1" stop-color="#FC5AFF" />
                                                                </linearGradient>
                                                                <clipPath id="clip0_4451_6757">
                                                                    <rect width="25" height="25" fill="white" transform="translate(14 14)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                    </div>
                                                    <div className="plsjist"
                                                    // onClick={() => {
                                                    //     location.pathname !== '/playlistnew' ? (navigate('/playlistnew', { state: { data: 'get' } })) : (getUserFav(), setType('liked'),
                                                    //         setPlaylistDetails({
                                                    //             ...PlaylistDetails,
                                                    //             name: "Liked Songs"
                                                    //         }))
                                                    // }}
                                                    >
                                                        Liked Songs
                                                        <div className="name-li">
                                                            <div className="display-1">
                                                                <svg width="12" height="12" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.3855 7.12403L12.2081 11.3154C12.5644 12.3053 12.7121 13.9631 11.1777 16.0029C11.0704 16.1464 10.9334 16.2649 10.776 16.3506C10.6187 16.4362 10.4447 16.4868 10.266 16.499C10.2363 16.499 10.2074 16.499 10.1777 16.499C10.0134 16.4991 9.85077 16.4667 9.69902 16.4038C9.54728 16.341 9.40941 16.2488 9.2933 16.1326L5.52299 12.3584L2.19252 15.6912C2.07524 15.8085 1.91618 15.8744 1.75033 15.8744C1.58448 15.8744 1.42542 15.8085 1.30814 15.6912C1.19087 15.5739 1.12498 15.4149 1.12498 15.249C1.12498 15.0832 1.19087 14.9241 1.30814 14.8068L4.64096 11.4764L0.867519 7.70294C0.743487 7.57875 0.646999 7.42983 0.584335 7.26589C0.521671 7.10194 0.494236 6.92663 0.503817 6.75137C0.513398 6.57612 0.559779 6.40484 0.639942 6.2487C0.720104 6.09256 0.832251 5.95505 0.969082 5.84512C2.95502 4.24278 4.8558 4.55762 5.68783 4.81856L9.87533 0.616217C9.99141 0.500114 10.1292 0.408014 10.2809 0.345178C10.4326 0.282342 10.5951 0.25 10.7593 0.25C10.9235 0.25 11.0861 0.282342 11.2377 0.345178C11.3894 0.408014 11.5272 0.500114 11.6433 0.616217L16.3847 5.35684C16.6191 5.59114 16.7508 5.9089 16.7509 6.24027C16.7511 6.57165 16.6196 6.88953 16.3855 7.12403Z" fill="#31D46A" />
                                                                </svg>
                                                                <div className="">
                                                                    {/* {type == 'liked' && songs?.length > 0 ? songs?.length : 0} songs */}
                                                                    {props?.likedSongsCounts == 0 ? 0 : props?.likedSongsCounts} songs

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="margin-top"
                                                onClick={() => {
                                                    if (tokens) {
                                                        if (location?.pathname !== '/playlist') {
                                                            navigate('/playlist', { state: { data: 'save' } })
                                                        }
                                                        else {
                                                            getUserSaved();
                                                            setType('saved');
                                                            setPlaylistDetails({
                                                                ...PlaylistDetails,
                                                                name: "Saved Songs",
                                                                img: '',
                                                                creator: '',
                                                                music_type: "",
                                                                description: ''
                                                            })
                                                        }
                                                    }
                                                    else {
                                                        toast.error("Login to Continue")
                                                        setTimeout(() => {
                                                            navigate('/login')
                                                        }, [2000])
                                                    }
                                                }}>
                                                <div className="display-1 lib-play cursor">
                                                    <div className="lib-ply">
                                                        <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="53" height="53" rx="5" fill="url(#paint0_linear_4964_4915)" />
                                                            <path d="M18 17C18 15.8954 18.8954 15 20 15H33C34.1046 15 35 15.8954 35 17V34.3237C35 35.9087 33.2442 36.8639 31.9135 36.0029L27.5865 33.203C26.9253 32.7752 26.0747 32.7752 25.4135 33.203L21.0865 36.0029C19.7558 36.8639 18 35.9087 18 34.3237V17Z" fill="white" />
                                                            <defs>
                                                                <linearGradient id="paint0_linear_4964_4915" x1="52.0536" y1="47.6172" x2="-1.80722" y2="43.4727" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="#175AFF" />
                                                                    <stop offset="1" stop-color="#FC5AFF" />
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>


                                                    </div>
                                                    <div className="plsjist"
                                                    // onClick={() => {
                                                    //     location.pathname !== '/playlistnew' ? navigate('/playlistnew', { state: { data: 'save' } }) : getUserSaved(); setType('saved');
                                                    //     setPlaylistDetails({
                                                    //         ...PlaylistDetails,
                                                    //         name: "Saved Songs"
                                                    //     })
                                                    // }}
                                                    >
                                                        Saved Songs
                                                        <div className="name-li">
                                                            <div className="display-1">
                                                                <svg width="12" height="12" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.3855 7.12403L12.2081 11.3154C12.5644 12.3053 12.7121 13.9631 11.1777 16.0029C11.0704 16.1464 10.9334 16.2649 10.776 16.3506C10.6187 16.4362 10.4447 16.4868 10.266 16.499C10.2363 16.499 10.2074 16.499 10.1777 16.499C10.0134 16.4991 9.85077 16.4667 9.69902 16.4038C9.54728 16.341 9.40941 16.2488 9.2933 16.1326L5.52299 12.3584L2.19252 15.6912C2.07524 15.8085 1.91618 15.8744 1.75033 15.8744C1.58448 15.8744 1.42542 15.8085 1.30814 15.6912C1.19087 15.5739 1.12498 15.4149 1.12498 15.249C1.12498 15.0832 1.19087 14.9241 1.30814 14.8068L4.64096 11.4764L0.867519 7.70294C0.743487 7.57875 0.646999 7.42983 0.584335 7.26589C0.521671 7.10194 0.494236 6.92663 0.503817 6.75137C0.513398 6.57612 0.559779 6.40484 0.639942 6.2487C0.720104 6.09256 0.832251 5.95505 0.969082 5.84512C2.95502 4.24278 4.8558 4.55762 5.68783 4.81856L9.87533 0.616217C9.99141 0.500114 10.1292 0.408014 10.2809 0.345178C10.4326 0.282342 10.5951 0.25 10.7593 0.25C10.9235 0.25 11.0861 0.282342 11.2377 0.345178C11.3894 0.408014 11.5272 0.500114 11.6433 0.616217L16.3847 5.35684C16.6191 5.59114 16.7508 5.9089 16.7509 6.24027C16.7511 6.57165 16.6196 6.88953 16.3855 7.12403Z" fill="#31D46A" />
                                                                </svg>
                                                                <div className="">
                                                                    {/* {type == 'saved' && songs?.length > 0 ? songs?.length : 0} songs */}
                                                                    {props?.savedSongsCount == 0 ? 0 : props?.savedSongsCount} songs

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            {inFolder ? <>
                                                <div className="display-2">
                                                    <Stack direction="row" spacing={2} className='margin-top' sx={{ alignItems: 'center' }} >
                                                        <div className='display-1'>
                                                            <ArrowBackIcon
                                                                onClick={() => { handleoutFolder(); getFolder(); setFolderId('') }}
                                                            />
                                                        </div>
                                                        <div className="plsjist">
                                                            New Folder
                                                        </div>
                                                    </Stack>

                                                </div>
                                                <div className="margin-top">

                                                    {/* {playlists.map((row, i) => (
                                                        <div key={row.id} className="display-1 lib-play cursor">
                                                            <div className="lib-ply">
                                                                <img src={playlistimg} alt="play" />
                                                            </div>
                                                            <div className="plsjist">
                                                                Playlist #{i + 1}
                                                                <div className="name-li">
                                                                    Playlist • {row.creator}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))} */}

                                                    {playlists?.length > 0 ?
                                                        playlists?.map((row, i) => (
                                                            row?.isAlive == true && row?.is_folder == true ?
                                                                <div className="display-2 lib-play cursor">
                                                                    <div key={row.id} className="display-1"
                                                                        onClick={() => {
                                                                            if (row?.song_name) {
                                                                                getOneTrack(row?._id)
                                                                            } else if (row?.album_name) {
                                                                                getOneAlbum(row?._id)
                                                                            } else if (row?.playlist) {
                                                                                getplayLists(row?._id)
                                                                            }
                                                                            // row?.song_name ?
                                                                            //     getOneTrack(row?._id) :
                                                                            //     row?.album_name ?
                                                                            //         getOneAlbum(row?._id) :
                                                                            //         row?.playlist ?
                                                                            //             getplayLists(row?._id) :
                                                                            //             null
                                                                            setPlaylistDetails({
                                                                                ...PlaylistDetails,
                                                                                id: row?._id,
                                                                                img: row?.image,
                                                                                name: row?.playlist,
                                                                                creator: row?.createUser?.[0].name,
                                                                                music_type: 'playlist',
                                                                                description: row?.description
                                                                            })
                                                                        }}
                                                                    >
                                                                        <div className="lib-ply">
                                                                            <img src={row?.image ? row?.image : playlistimg} alt="play" />
                                                                        </div>
                                                                        <div className="plsjist">
                                                                            {row?.playlist ?
                                                                                row?.playlist?.length > 15 ?
                                                                                    row?.playlist.slice(0, 15) + '...' : row?.playlist :
                                                                                row?.album_name ?
                                                                                    row?.album_name?.length > 15 ? row?.album_name.slice(0, 15) + '...' : row?.album_name :
                                                                                    row?.song_name ?
                                                                                        row?.song_name?.length > 15 ? row?.song_name.slice(0, 15) + '...' : row?.song_name : <></>
                                                                            }
                                                                            {/* <div className="name-li">
                                                            {row?.users?.[0]?.name}
                                                        </div>
                                                        <div className="name-li">
                                                            {row?.music_type}
                                                        </div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip title='Remove from Folder' placement="top">
                                                                            <DeleteOutlineIcon onClick={() => {
                                                                                deletemovetofolder(row?._id,
                                                                                    row?.playlist ? 'playlist' :
                                                                                        row?.album_name ?
                                                                                            'album' :
                                                                                            row?.song_name ?
                                                                                                'track' : <></>)
                                                                            }} />
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>

                                                                : <>
                                                                    <div className='text-center'>
                                                                        <img width="100" height="100" src="https://img.icons8.com/external-filled-outline-berkahicon/100/external-al-eid-al-fitr-filled-outline-berkahicon-15.png" alt="external-al-eid-al-fitr-filled-outline-berkahicon-15" />
                                                                        <div style={{ color: "white", textAlign: 'center' }}>No Albums Found</div>
                                                                    </div>
                                                                </>
                                                        )) :
                                                        <div>
                                                            <div className='text-center'>
                                                                <img width="100" height="100" src="https://img.icons8.com/external-filled-outline-berkahicon/100/external-al-eid-al-fitr-filled-outline-berkahicon-15.png" alt="external-al-eid-al-fitr-filled-outline-berkahicon-15" />
                                                                <div style={{ color: "white", textAlign: 'center' }}>No Songs Found</div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </> : <>
                                                {folder && folder?.map((row, i) => (
                                                    <div className="margin-top" >
                                                        <div className="display-2 lib-play cursor"
                                                        // onClick={handleInFolder}
                                                        >
                                                            <div key={row.id} className="display-1 " >
                                                                <div className='newfolder-svg' onClick={() => { handleInFolder(); setFolderId(row?._id); getFolder(row?._id) }}>
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7.64102 4.99967L9.30768 6.66634H16.666V14.9997H3.33268V4.99967H7.64102ZM8.33268 3.33301H3.33268C2.41602 3.33301 1.67435 4.08301 1.67435 4.99967L1.66602 14.9997C1.66602 15.9163 2.41602 16.6663 3.33268 16.6663H16.666C17.5827 16.6663 18.3327 15.9163 18.3327 14.9997V6.66634C18.3327 5.74967 17.5827 4.99967 16.666 4.99967H9.99935L8.33268 3.33301Z" fill="#636363" />
                                                                    </svg>


                                                                </div>
                                                                <div className="plsjist" onClick={() => { handleInFolder(); setFolderId(row?._id); getFolder(row?._id) }} >
                                                                    {/* New Folder */} {row?.folder_name}
                                                                    <div className="name-li" onClick={() => { handleInFolder(); setFolderId(row?._id); getFolder(row?._id) }}>
                                                                        {/* Playlist  #{i + 1} {row?.users?.[0]?.name} {`${row?.playlist?.length} playlist`} */}
                                                                        {/* {`${playlists?.length} songs`} */}
                                                                        {`${row?.PlaylistCount != undefined ? row?.PlaylistCount : 0} songs`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="display-1">
                                                                <ArrowRightIcon onClick={() => { handleInFolder(); setFolderId(row?._id); getFolder(row?._id) }} />

                                                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { handleOpen2(); setDeleteFolderId(row?._id) }}>
                                                                    <path d="M14.875 3.375H1.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M6.125 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M9.875 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M13.625 3.375V15.25C13.625 15.4158 13.5592 15.5747 13.4419 15.6919C13.3247 15.8092 13.1658 15.875 13 15.875H3C2.83424 15.875 2.67527 15.8092 2.55806 15.6919C2.44085 15.5747 2.375 15.4158 2.375 15.25V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M11.125 3.375V2.125C11.125 1.79348 10.9933 1.47554 10.7589 1.24112C10.5245 1.0067 10.2065 0.875 9.875 0.875H6.125C5.79348 0.875 5.47554 1.0067 5.24112 1.24112C5.0067 1.47554 4.875 1.79348 4.875 2.125V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>

                                                                <ModeEditIcon fontSize='10px' onClick={() => { handleOpen3(); setNameId(row?._id) }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))}


                                            </>}

                                            <div className="margin-top"
                                                onClick={() => {
                                                    handleTrack(); getMusicLibrary(); setPlaylistDetails({
                                                        ...PlaylistDetails,
                                                        name: "Tracks",
                                                        img: '',
                                                        creator: '',
                                                        music_type: "",
                                                        description: ''
                                                    })
                                                }}
                                            >
                                                <div className="display-2 lib-play cursor">
                                                    <div className="display-1 " >
                                                        <div className='newfolder-svg'>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.64102 4.99967L9.30768 6.66634H16.666V14.9997H3.33268V4.99967H7.64102ZM8.33268 3.33301H3.33268C2.41602 3.33301 1.67435 4.08301 1.67435 4.99967L1.66602 14.9997C1.66602 15.9163 2.41602 16.6663 3.33268 16.6663H16.666C17.5827 16.6663 18.3327 15.9163 18.3327 14.9997V6.66634C18.3327 5.74967 17.5827 4.99967 16.666 4.99967H9.99935L8.33268 3.33301Z" fill="#636363" />
                                                            </svg>


                                                        </div>
                                                        <div className="plsjist" >
                                                            Track
                                                            <div className="name-li">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {localStorage.getItem('role') == 'artist' ?
                                                <>


                                                    <div className="margin-top"
                                                        onClick={() => {
                                                            handleAlbum(); props?.setSongs([]); setPlaylistDetails({
                                                                ...PlaylistDetails,
                                                                name: "Albums",
                                                                img: '',
                                                                creator: '',
                                                                music_type: "",
                                                                description: ''
                                                            })
                                                        }}
                                                    >
                                                        <div className="display-2 lib-play cursor">
                                                            <div className="display-1 " >
                                                                <div className='newfolder-svg'>
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7.64102 4.99967L9.30768 6.66634H16.666V14.9997H3.33268V4.99967H7.64102ZM8.33268 3.33301H3.33268C2.41602 3.33301 1.67435 4.08301 1.67435 4.99967L1.66602 14.9997C1.66602 15.9163 2.41602 16.6663 3.33268 16.6663H16.666C17.5827 16.6663 18.3327 15.9163 18.3327 14.9997V6.66634C18.3327 5.74967 17.5827 4.99967 16.666 4.99967H9.99935L8.33268 3.33301Z" fill="#636363" />
                                                                    </svg>


                                                                </div>
                                                                <div className="plsjist" >
                                                                    Album
                                                                    <div className="name-li">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="margin-top"
                                                        onClick={() => {
                                                            handlePlaylist(); props?.setSongs([]); setPlaylistDetails({
                                                                ...PlaylistDetails,
                                                                name: "Playlists",
                                                                img: '',
                                                                creator: '',
                                                                music_type: "",
                                                                description: ''
                                                            })
                                                        }}
                                                    >
                                                        <div className="display-2 lib-play cursor">
                                                            <div className="display-1 " >
                                                                <div className='newfolder-svg'>
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7.64102 4.99967L9.30768 6.66634H16.666V14.9997H3.33268V4.99967H7.64102ZM8.33268 3.33301H3.33268C2.41602 3.33301 1.67435 4.08301 1.67435 4.99967L1.66602 14.9997C1.66602 15.9163 2.41602 16.6663 3.33268 16.6663H16.666C17.5827 16.6663 18.3327 15.9163 18.3327 14.9997V6.66634C18.3327 5.74967 17.5827 4.99967 16.666 4.99967H9.99935L8.33268 3.33301Z" fill="#636363" />
                                                                    </svg>


                                                                </div>
                                                                <div className="plsjist" >
                                                                    Playlist
                                                                    <div className="name-li">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : <></>}
                                        </>}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <div className="margin-top">
                                {
                                    play?.length > 0 ?
                                        play?.map((row, i) => (
                                            // row?.is_folder == false ?
                                            <div className='display-2 lib-play cursor'>
                                                <div key={row.id} className="display-1 "
                                                    // onClick={() => {
                                                    //     location.pathname !== '/playlistnew' ? (navigate('/playlistnew', { state: { data: row?._id, music_type: 'playlist', row: row } })) :
                                                    //         (getplayLists(row._id),
                                                    //             setPlaylistDetails({ ...PlaylistDetails, id: row?._id, img: row?.image, name: row?.playlist, creator: row?.createUser?.[0].name, music_type: 'playlist', description: row?.description }))
                                                    // }}

                                                    onClick={() => {
                                                        if (location?.pathname !== '/playlist') {
                                                            navigate('/playlist', { state: { data: row?._id, music_type: 'playlist', row: row } })
                                                        }
                                                        else {
                                                            getplayLists(row._id);
                                                            setPlaylistDetails({
                                                                ...PlaylistDetails,
                                                                id: row?._id,
                                                                img: row?.image,
                                                                name: row?.playlist,
                                                                creator: row?.createUser?.[0].name,
                                                                music_type: 'playlist',
                                                                description: row?.description
                                                            })
                                                        }
                                                    }}
                                                >
                                                    <div className="lib-ply">
                                                        <img src={row?.image ? row?.image : playlistimg} alt="play" />
                                                    </div>
                                                    <div className="plsjist">
                                                        {/* Playlist #{i + 1} */} {row?.playlist?.length > 15 ? row?.playlist?.slice(0, 15) + '...' : row?.playlist}
                                                        <div className="name-li">
                                                            {/* Playlist • {row.creator} */} {row?.createUser?.[0].name}
                                                        </div>
                                                    </div>

                                                    {
                                                        row?.userPin ?
                                                            <div
                                                            // onClick={() => { pinList() }}
                                                            >
                                                                <svg width="12" height="12" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.3855 7.12403L12.2081 11.3154C12.5644 12.3053 12.7121 13.9631 11.1777 16.0029C11.0704 16.1464 10.9334 16.2649 10.776 16.3506C10.6187 16.4362 10.4447 16.4868 10.266 16.499C10.2363 16.499 10.2074 16.499 10.1777 16.499C10.0134 16.4991 9.85077 16.4667 9.69902 16.4038C9.54728 16.341 9.40941 16.2488 9.2933 16.1326L5.52299 12.3584L2.19252 15.6912C2.07524 15.8085 1.91618 15.8744 1.75033 15.8744C1.58448 15.8744 1.42542 15.8085 1.30814 15.6912C1.19087 15.5739 1.12498 15.4149 1.12498 15.249C1.12498 15.0832 1.19087 14.9241 1.30814 14.8068L4.64096 11.4764L0.867519 7.70294C0.743487 7.57875 0.646999 7.42983 0.584335 7.26589C0.521671 7.10194 0.494236 6.92663 0.503817 6.75137C0.513398 6.57612 0.559779 6.40484 0.639942 6.2487C0.720104 6.09256 0.832251 5.95505 0.969082 5.84512C2.95502 4.24278 4.8558 4.55762 5.68783 4.81856L9.87533 0.616217C9.99141 0.500114 10.1292 0.408014 10.2809 0.345178C10.4326 0.282342 10.5951 0.25 10.7593 0.25C10.9235 0.25 11.0861 0.282342 11.2377 0.345178C11.3894 0.408014 11.5272 0.500114 11.6433 0.616217L16.3847 5.35684C16.6191 5.59114 16.7508 5.9089 16.7509 6.24027C16.7511 6.57165 16.6196 6.88953 16.3855 7.12403Z" fill="#31D46A" />
                                                                </svg>
                                                            </div>
                                                            : null
                                                    }

                                                </div>
                                                {
                                                    row?.userFound ?
                                                        <MoreVertIcon onClick={(e) => { handleClickDynamic(e); setId(row?._id) }} />
                                                        : <></>
                                                }
                                            </div>

                                            // :
                                            // <></>
                                        )) :
                                        <>
                                            <div className='text-center'>
                                                <img width="100" height="100" src="https://img.icons8.com/external-filled-outline-berkahicon/100/external-al-eid-al-fitr-filled-outline-berkahicon-15.png" alt="external-al-eid-al-fitr-filled-outline-berkahicon-15" />
                                                <div style={{ color: "white", textAlign: 'center' }}>No Albums Found</div>
                                            </div>
                                        </>
                                }
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <div className="margin-top">
                                {
                                    otherAlbums?.length > 0 ?

                                        otherAlbums?.map((row, i) => (
                                            <div className='display-2 lib-play cursor'>
                                                <div key={row.id} className="display-1 "
                                                    // onClick={() => {
                                                    //     location.pathname !== '/playlistnew' ? (navigate('/playlistnew', { state: { data: row?._id, music_type: 'album', row: row } })) :
                                                    //         (getOneAlbum(row._id),
                                                    //             setPlaylistDetails({ ...PlaylistDetails, id: row?._id, img: row?.image, name: row?.album_name, creator: row?.createUser?.[0].name, music_type: 'album', description: row?.description }))
                                                    // }}
                                                    onClick={() => {
                                                        if (location?.pathname !== '/playlist') {
                                                            navigate('/playlist', { state: { data: row?._id, music_type: 'album', row: row } })
                                                        }
                                                        else {
                                                            getOneAlbum(row._id);
                                                            setPlaylistDetails({
                                                                ...PlaylistDetails,
                                                                id: row?._id,
                                                                img: row?.image,
                                                                name: row?.album_name,
                                                                creator: row?.createUser?.[0].name,
                                                                music_type: 'album',
                                                                description: row?.description
                                                            })
                                                        }
                                                    }}
                                                >
                                                    <div className="lib-ply">
                                                        <img src={row?.image ? row?.image : playlistimg} alt="play" />
                                                    </div>
                                                    <div className="plsjist">
                                                        {/* Playlist #{i + 1} */}
                                                        {row?.album_name.length > 15 ? row?.album_name.slice(0, 15) + '...' : row?.album_name}
                                                        <div className="name-li">
                                                            {/* Playlist • {row.creator} */} {row?.createUser?.[0].name}
                                                        </div>
                                                    </div>

                                                    {
                                                        row?.userPin ?
                                                            <div
                                                            // onClick={() => { pinList() }}
                                                            >
                                                                <svg width="12" height="12" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.3855 7.12403L12.2081 11.3154C12.5644 12.3053 12.7121 13.9631 11.1777 16.0029C11.0704 16.1464 10.9334 16.2649 10.776 16.3506C10.6187 16.4362 10.4447 16.4868 10.266 16.499C10.2363 16.499 10.2074 16.499 10.1777 16.499C10.0134 16.4991 9.85077 16.4667 9.69902 16.4038C9.54728 16.341 9.40941 16.2488 9.2933 16.1326L5.52299 12.3584L2.19252 15.6912C2.07524 15.8085 1.91618 15.8744 1.75033 15.8744C1.58448 15.8744 1.42542 15.8085 1.30814 15.6912C1.19087 15.5739 1.12498 15.4149 1.12498 15.249C1.12498 15.0832 1.19087 14.9241 1.30814 14.8068L4.64096 11.4764L0.867519 7.70294C0.743487 7.57875 0.646999 7.42983 0.584335 7.26589C0.521671 7.10194 0.494236 6.92663 0.503817 6.75137C0.513398 6.57612 0.559779 6.40484 0.639942 6.2487C0.720104 6.09256 0.832251 5.95505 0.969082 5.84512C2.95502 4.24278 4.8558 4.55762 5.68783 4.81856L9.87533 0.616217C9.99141 0.500114 10.1292 0.408014 10.2809 0.345178C10.4326 0.282342 10.5951 0.25 10.7593 0.25C10.9235 0.25 11.0861 0.282342 11.2377 0.345178C11.3894 0.408014 11.5272 0.500114 11.6433 0.616217L16.3847 5.35684C16.6191 5.59114 16.7508 5.9089 16.7509 6.24027C16.7511 6.57165 16.6196 6.88953 16.3855 7.12403Z" fill="#31D46A" />
                                                                </svg>
                                                            </div>
                                                            : null
                                                    }

                                                </div>
                                                {
                                                    row?.userFound ?
                                                        <MoreVertIcon onClick={(e) => { handleClickDynamic(e); setId(row?._id) }} />
                                                        : <></>
                                                }
                                            </div>

                                        )) :
                                        <><p style={{ color: "white" }}>No Album Found</p></>
                                }
                            </div>
                        </CustomTabPanel>

                        <div className="pad-24">


                            {
                                trackOpen ?
                                    <div className="margin-top">

                                        {track?.length > 0 ?
                                            track?.map((row, i) => (
                                                <div className="display-2 lib-play cursor">


                                                    <div key={row.id} className="display-1 "
                                                        onClick={() => {
                                                            if (location.pathname !== '/playlist') {
                                                                navigate('/playlist', {
                                                                    state: {
                                                                        data: row?.music_type === 'track' ? row?._id :
                                                                            row?.music_type === 'album' ? row?.album_id :
                                                                                row?.play_id,
                                                                        music_type: row?.music_type,
                                                                        row: row
                                                                    }
                                                                });
                                                            } else {
                                                                getOneTrack(row._id);
                                                                setPlaylistDetails({
                                                                    ...PlaylistDetails,
                                                                    id: row?._id,
                                                                    img: row?.image,
                                                                    name: row?.song_name,
                                                                    creator: row?.createUser?.[0]?.name,
                                                                    music_type: row?.music_type,
                                                                    description: row?.description
                                                                });
                                                            }
                                                        }}

                                                    >
                                                        <div className="lib-ply">
                                                            <img src={row?.image ? row?.image : playlistimg} alt="play" />
                                                        </div>
                                                        <div className="plsjist">

                                                            {row?.song_name.length > 15 ? row?.song_name.slice(0, 15) + '...' : row?.song_name}
                                                            <div className="name-li">
                                                                {row?.createUser?.[0]?.name}
                                                            </div>
                                                            {/* <div className="name-li">
                                                        {row?.music_type}
                                                    </div> */}

                                                        </div>

                                                    </div>
                                                    {
                                                        row?.userPin ?
                                                            <div
                                                            // onClick={() => { pinList() }}
                                                            >
                                                                <svg width="12" height="12" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.3855 7.12403L12.2081 11.3154C12.5644 12.3053 12.7121 13.9631 11.1777 16.0029C11.0704 16.1464 10.9334 16.2649 10.776 16.3506C10.6187 16.4362 10.4447 16.4868 10.266 16.499C10.2363 16.499 10.2074 16.499 10.1777 16.499C10.0134 16.4991 9.85077 16.4667 9.69902 16.4038C9.54728 16.341 9.40941 16.2488 9.2933 16.1326L5.52299 12.3584L2.19252 15.6912C2.07524 15.8085 1.91618 15.8744 1.75033 15.8744C1.58448 15.8744 1.42542 15.8085 1.30814 15.6912C1.19087 15.5739 1.12498 15.4149 1.12498 15.249C1.12498 15.0832 1.19087 14.9241 1.30814 14.8068L4.64096 11.4764L0.867519 7.70294C0.743487 7.57875 0.646999 7.42983 0.584335 7.26589C0.521671 7.10194 0.494236 6.92663 0.503817 6.75137C0.513398 6.57612 0.559779 6.40484 0.639942 6.2487C0.720104 6.09256 0.832251 5.95505 0.969082 5.84512C2.95502 4.24278 4.8558 4.55762 5.68783 4.81856L9.87533 0.616217C9.99141 0.500114 10.1292 0.408014 10.2809 0.345178C10.4326 0.282342 10.5951 0.25 10.7593 0.25C10.9235 0.25 11.0861 0.282342 11.2377 0.345178C11.3894 0.408014 11.5272 0.500114 11.6433 0.616217L16.3847 5.35684C16.6191 5.59114 16.7508 5.9089 16.7509 6.24027C16.7511 6.57165 16.6196 6.88953 16.3855 7.12403Z" fill="#31D46A" />
                                                                </svg>
                                                            </div> : null
                                                    }
                                                </div>
                                            )) :
                                            <div className='text-center'>
                                                <img width="100" height="100" src="https://img.icons8.com/external-filled-outline-berkahicon/100/external-al-eid-al-fitr-filled-outline-berkahicon-15.png" alt="external-al-eid-al-fitr-filled-outline-berkahicon-15" />
                                                <div style={{ color: "white", textAlign: 'center' }}>No Tracks Found</div>
                                            </div>
                                        }
                                    </div> :
                                    albumOpen ?
                                        <div className="margin-top">
                                            {
                                                album?.length > 0 ?

                                                    album?.map((row, i) => (
                                                        <div className="display-2 lib-play cursor">
                                                            <div key={row.id} className="display-1 "
                                                                onClick={() => {
                                                                    if (location?.pathname !== '/playlist') {
                                                                        navigate('/playlist', { state: { data: row?._id, music_type: 'album', row: row } })
                                                                    }
                                                                    else {
                                                                        getOneAlbum(row._id);
                                                                        setPlaylistDetails({
                                                                            ...PlaylistDetails,
                                                                            id: row?._id,
                                                                            img: row?.image,
                                                                            name: row?.album_name,
                                                                            creator: row?.createUser?.[0].name,
                                                                            music_type: 'album',
                                                                            description: row?.description
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <div className="lib-ply">
                                                                    <img src={row?.image ? row?.image : playlistimg} alt="play" />
                                                                </div>
                                                                <div className="plsjist">
                                                                    {row?.album_name.length > 15 ? row?.album_name.slice(0, 15) + '...' : row?.album_name}
                                                                    <div className="name-li">
                                                                        {row?.createUser?.[0]?.name}
                                                                    </div>
                                                                </div>


                                                            </div>


                                                            {
                                                                row?.userPin ?
                                                                    <div
                                                                    // onClick={() => { pinList() }}
                                                                    >
                                                                        <svg width="12" height="12" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M16.3855 7.12403L12.2081 11.3154C12.5644 12.3053 12.7121 13.9631 11.1777 16.0029C11.0704 16.1464 10.9334 16.2649 10.776 16.3506C10.6187 16.4362 10.4447 16.4868 10.266 16.499C10.2363 16.499 10.2074 16.499 10.1777 16.499C10.0134 16.4991 9.85077 16.4667 9.69902 16.4038C9.54728 16.341 9.40941 16.2488 9.2933 16.1326L5.52299 12.3584L2.19252 15.6912C2.07524 15.8085 1.91618 15.8744 1.75033 15.8744C1.58448 15.8744 1.42542 15.8085 1.30814 15.6912C1.19087 15.5739 1.12498 15.4149 1.12498 15.249C1.12498 15.0832 1.19087 14.9241 1.30814 14.8068L4.64096 11.4764L0.867519 7.70294C0.743487 7.57875 0.646999 7.42983 0.584335 7.26589C0.521671 7.10194 0.494236 6.92663 0.503817 6.75137C0.513398 6.57612 0.559779 6.40484 0.639942 6.2487C0.720104 6.09256 0.832251 5.95505 0.969082 5.84512C2.95502 4.24278 4.8558 4.55762 5.68783 4.81856L9.87533 0.616217C9.99141 0.500114 10.1292 0.408014 10.2809 0.345178C10.4326 0.282342 10.5951 0.25 10.7593 0.25C10.9235 0.25 11.0861 0.282342 11.2377 0.345178C11.3894 0.408014 11.5272 0.500114 11.6433 0.616217L16.3847 5.35684C16.6191 5.59114 16.7508 5.9089 16.7509 6.24027C16.7511 6.57165 16.6196 6.88953 16.3855 7.12403Z" fill="#31D46A" />
                                                                        </svg>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>

                                                    )) :
                                                    <div className='text-center'>
                                                        <img width="100" height="100" src="https://img.icons8.com/external-filled-outline-berkahicon/100/external-al-eid-al-fitr-filled-outline-berkahicon-15.png" alt="external-al-eid-al-fitr-filled-outline-berkahicon-15" />
                                                        <div style={{ color: "white", textAlign: 'center' }}>No Albums Found</div>
                                                    </div>
                                            }
                                        </div> :
                                        playlistOpen ?
                                            <div className="margin-top">
                                                {
                                                    playlist?.length > 0 ?
                                                        playlist?.map((row, i) => (
                                                            row?.is_folder == false ?
                                                                <div className="display-2 lib-play cursor">
                                                                    <div key={row.id} className="display-1 "
                                                                        onClick={() => {
                                                                            if (location?.pathname !== '/playlist') {
                                                                                navigate('/playlist', { state: { data: row?._id, music_type: 'playlist', row: row } })
                                                                            }
                                                                            else {
                                                                                getplayLists(row._id);
                                                                                setPlaylistDetails({
                                                                                    ...PlaylistDetails,
                                                                                    id: row?._id,
                                                                                    img: row?.image,
                                                                                    name: row?.playlist,
                                                                                    creator: row?.createUser?.[0].name,
                                                                                    music_type: 'playlist',
                                                                                    description: row?.description
                                                                                })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className="lib-ply">
                                                                            <img src={row?.image ? row?.image : playlistimg} alt="play" />
                                                                        </div>
                                                                        <div className="plsjist">
                                                                            {row?.playlist?.length > 15 ? row?.playlist?.slice(0, 15) + '...' : row?.playlist}
                                                                            <div className="name-li">
                                                                                {row?.createUser?.[0].name}
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                    {
                                                                        row?.userPin ?
                                                                            <div
                                                                            // onClick={() => { pinList() }}
                                                                            >
                                                                                <svg width="12" height="12" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M16.3855 7.12403L12.2081 11.3154C12.5644 12.3053 12.7121 13.9631 11.1777 16.0029C11.0704 16.1464 10.9334 16.2649 10.776 16.3506C10.6187 16.4362 10.4447 16.4868 10.266 16.499C10.2363 16.499 10.2074 16.499 10.1777 16.499C10.0134 16.4991 9.85077 16.4667 9.69902 16.4038C9.54728 16.341 9.40941 16.2488 9.2933 16.1326L5.52299 12.3584L2.19252 15.6912C2.07524 15.8085 1.91618 15.8744 1.75033 15.8744C1.58448 15.8744 1.42542 15.8085 1.30814 15.6912C1.19087 15.5739 1.12498 15.4149 1.12498 15.249C1.12498 15.0832 1.19087 14.9241 1.30814 14.8068L4.64096 11.4764L0.867519 7.70294C0.743487 7.57875 0.646999 7.42983 0.584335 7.26589C0.521671 7.10194 0.494236 6.92663 0.503817 6.75137C0.513398 6.57612 0.559779 6.40484 0.639942 6.2487C0.720104 6.09256 0.832251 5.95505 0.969082 5.84512C2.95502 4.24278 4.8558 4.55762 5.68783 4.81856L9.87533 0.616217C9.99141 0.500114 10.1292 0.408014 10.2809 0.345178C10.4326 0.282342 10.5951 0.25 10.7593 0.25C10.9235 0.25 11.0861 0.282342 11.2377 0.345178C11.3894 0.408014 11.5272 0.500114 11.6433 0.616217L16.3847 5.35684C16.6191 5.59114 16.7508 5.9089 16.7509 6.24027C16.7511 6.57165 16.6196 6.88953 16.3855 7.12403Z" fill="#31D46A" />
                                                                                </svg>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>

                                                                :
                                                                <></>
                                                        )) :
                                                        <div className='text-center'>
                                                            <img width="100" height="100" src="https://img.icons8.com/external-filled-outline-berkahicon/100/external-al-eid-al-fitr-filled-outline-berkahicon-15.png" alt="external-al-eid-al-fitr-filled-outline-berkahicon-15" />
                                                            <div style={{ color: "white", textAlign: 'center' }}>No Playlists Found</div>
                                                        </div>
                                                }
                                            </div> : <></>
                            }
                        </div>
                    </Box>


                </Grid>
            </Grid>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >
                <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>
                    <div className=' display-1 cursor' onClick={() => { addPlaylist() }}>
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.66602 1.66699H18.3327V10.8337H16.666V3.33366H3.33268V16.667H10.8327V18.3337H1.66602V1.66699ZM9.99935 5.83366H13.3327V7.50033H11.666V11.667C11.6657 12.1976 11.4966 12.7144 11.1832 13.1426C10.8697 13.5707 10.4281 13.888 9.92234 14.0485C9.41657 14.2091 8.87284 14.2045 8.36984 14.0355C7.86683 13.8665 7.43064 13.5419 7.1244 13.1085C6.81815 12.6752 6.65773 12.1556 6.66635 11.6251C6.67497 11.0945 6.85218 10.5804 7.17234 10.1573C7.49251 9.73412 7.93901 9.4238 8.44724 9.27124C8.95547 9.11867 9.49906 9.13178 9.99935 9.30866V5.83366ZM9.99935 11.667C9.99935 11.446 9.91155 11.234 9.75527 11.0777C9.59899 10.9215 9.38703 10.8337 9.16602 10.8337C8.945 10.8337 8.73304 10.9215 8.57676 11.0777C8.42048 11.234 8.33268 11.446 8.33268 11.667C8.33268 11.888 8.42048 12.1 8.57676 12.2562C8.73304 12.4125 8.945 12.5003 9.16602 12.5003C9.38703 12.5003 9.59899 12.4125 9.75527 12.2562C9.91155 12.1 9.99935 11.888 9.99935 11.667ZM16.666 12.5003V15.0003H19.166V16.667H16.666V19.167H14.9993V16.667H12.4993V15.0003H14.9993V12.5003H16.666Z" fill="#636363" />
                            </svg>

                        </div>
                        <div>
                            <div className='frstname' >
                                Create PlayList
                            </div>

                        </div>

                    </div>
                    <div className=' display-1 cursor' onClick={() => { addFolder() }} >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.64102 4.99967L9.30768 6.66634H16.666V14.9997H3.33268V4.99967H7.64102ZM8.33268 3.33301H3.33268C2.41602 3.33301 1.67435 4.08301 1.67435 4.99967L1.66602 14.9997C1.66602 15.9163 2.41602 16.6663 3.33268 16.6663H16.666C17.5827 16.6663 18.3327 15.9163 18.3327 14.9997V6.66634C18.3327 5.74967 17.5827 4.99967 16.666 4.99967H9.99935L8.33268 3.33301Z" fill="#636363" />
                            </svg>
                        </div>
                        <div>
                            <div className='frstname' >
                                Create a Folder
                            </div>

                        </div>

                    </div>
                </div>
            </Popover>
            <Modal
                open={model2}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal-style"
            >
                <Box sx={style3}>
                    <div className="display-2">
                        <div className="edtdet">
                            Are You Sure To Delete This Folder
                        </div>
                        <div className="cls" onClick={handleClose5}>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.0625 0.9375L0.9375 9.0625M0.9375 0.9375L9.0625 9.0625" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>


                    </div>
                    <div className="sve text-center">
                        <Button
                            onClick={() => { deleteFolder(deleteFolderId) }}
                        >
                            Delete
                        </Button>
                    </div>
                    {/* <div className="by-precee" style={{ color: 'var(--clr-font)' }}>
                                    By proceeding, you agree to give Dreamster access to the image you choose to Upload Please make sure you have the right to upload the image.
                                </div> */}



                </Box>
            </Modal>
            <Modal
                open={model3}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal-style"
            >
                <Box sx={style3}>
                    <div className="display-2">
                        <div className="edtdet">
                            Edit Details
                        </div>
                        <div className="cls" onClick={handleClose6}>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.0625 0.9375L0.9375 9.0625M0.9375 0.9375L9.0625 9.0625" stroke="var(--clr-font)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <div className="text-field mdoel-inpu mg-top-10">
                        <TextField
                            id="outlined-multiline-static"
                            placeholder="Folder Name"
                            value={folderName}
                            onChange={(e) => { setFolderName(e.target.value) }}
                        />
                        {folderNameErr && <p>{folderNameErr}</p>}
                    </div>
                    <div className="sve text-center">
                        <Button
                            onClick={() => { editFolder() }}
                        >
                            Save
                        </Button>
                    </div>
                </Box>
            </Modal>
            <Popover
                id={idD}
                open={openDynamic}
                anchorEl={dynamicMore}
                onClose={handleCloseDynamic}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >
                <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>
                    {/* <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2782)">
                                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2782">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div>
                            <div className='frstname' >
                                Remove from profile
                            </div>

                        </div>

                    </div>

                    <hr style={{ borderColor: '#ffffff5c' }} />
                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 15.8751H1.75C1.58424 15.8751 1.42527 15.8093 1.30806 15.692C1.19085 15.5748 1.125 15.4159 1.125 15.2501V11.7587C1.12508 11.5932 1.19082 11.4344 1.30781 11.3173L10.9422 1.68291C11.0594 1.56579 11.2183 1.5 11.384 1.5C11.5497 1.5 11.7086 1.56579 11.8258 1.68291L15.3172 5.17198C15.4343 5.28917 15.5001 5.44808 15.5001 5.61377C15.5001 5.77946 15.4343 5.93837 15.3172 6.05557L5.5 15.8751Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.875 15.875H5.5" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.625 4L13 8.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>



                        </div>
                        <div>
                            <div className='frstname' >
                                Edit details
                            </div>

                        </div>

                    </div>
                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.875 3.375H1.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.125 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.875 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.625 3.375V15.25C13.625 15.4158 13.5592 15.5747 13.4419 15.6919C13.3247 15.8092 13.1658 15.875 13 15.875H3C2.83424 15.875 2.67527 15.8092 2.55806 15.6919C2.44085 15.5747 2.375 15.4158 2.375 15.25V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.125 3.375V2.125C11.125 1.79348 10.9933 1.47554 10.7589 1.24112C10.5245 1.0067 10.2065 0.875 9.875 0.875H6.125C5.79348 0.875 5.47554 1.0067 5.24112 1.24112C5.0067 1.47554 4.875 1.79348 4.875 2.125V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>


                        </div>
                        <div>
                            <div className='frstname' >
                                Delete
                            </div>

                        </div>

                    </div>
                    <hr style={{ borderColor: '#ffffff5c' }} />
                    <div className=' display-1 cursor' onClick={() => { addPlaylist() }}>
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.66602 1.66699H18.3327V10.8337H16.666V3.33366H3.33268V16.667H10.8327V18.3337H1.66602V1.66699ZM9.99935 5.83366H13.3327V7.50033H11.666V11.667C11.6657 12.1976 11.4966 12.7144 11.1832 13.1426C10.8697 13.5707 10.4281 13.888 9.92234 14.0485C9.41657 14.2091 8.87284 14.2045 8.36984 14.0355C7.86683 13.8665 7.43064 13.5419 7.1244 13.1085C6.81815 12.6752 6.65773 12.1556 6.66635 11.6251C6.67497 11.0945 6.85218 10.5804 7.17234 10.1573C7.49251 9.73412 7.93901 9.4238 8.44724 9.27124C8.95547 9.11867 9.49906 9.13178 9.99935 9.30866V5.83366ZM9.99935 11.667C9.99935 11.446 9.91155 11.234 9.75527 11.0777C9.59899 10.9215 9.38703 10.8337 9.16602 10.8337C8.945 10.8337 8.73304 10.9215 8.57676 11.0777C8.42048 11.234 8.33268 11.446 8.33268 11.667C8.33268 11.888 8.42048 12.1 8.57676 12.2562C8.73304 12.4125 8.945 12.5003 9.16602 12.5003C9.38703 12.5003 9.59899 12.4125 9.75527 12.2562C9.91155 12.1 9.99935 11.888 9.99935 11.667ZM16.666 12.5003V15.0003H19.166V16.667H16.666V19.167H14.9993V16.667H12.4993V15.0003H14.9993V12.5003H16.666Z" fill="#636363" />
                            </svg>

                        </div>
                        <div>
                            <div className='frstname' >
                                Create PlayList
                            </div>

                        </div>

                    </div>
                    <div className=' display-1 cursor' onClick={addFolder} >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2802)">
                                    <path d="M3.125 10H16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 3.125V16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2802">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <div>
                            <div className='frstname' >
                                Create a Playlist folder
                            </div>

                        </div>

                    </div> */}
                    <div className=' display-1 cursor'
                        onClick={() => {
                            if (props?.type == 'playlist' || type === 'playlist') {
                                addplayLibrary(ID)
                            }
                            else if (props?.type == 'album' || type === 'album') {
                                addalbumLibrary(ID)
                            }
                            else if (props?.type == 'track' || type === 'track') {
                                addLibrary(ID)
                            }
                        }}
                    >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.8227 10.3539L12.6275 12.1587L12.1596 12.6266L10.3549 10.8218L10.0013 10.4682L9.64775 10.8218L7.84297 12.6266L7.37508 12.1587L9.17986 10.3539L9.53341 10.0003L9.17986 9.64677L7.37508 7.84199L7.84297 7.3741L9.64775 9.17888L10.0013 9.53243L10.3549 9.17888L12.1596 7.3741L12.6275 7.84199L10.8227 9.64677L10.4692 10.0003L10.8227 10.3539ZM2.83464 10.0003C2.83464 13.9515 6.05016 17.167 10.0013 17.167C13.9524 17.167 17.168 13.9515 17.168 10.0003C17.168 6.04918 13.9524 2.83366 10.0013 2.83366C6.05016 2.83366 2.83464 6.04918 2.83464 10.0003ZM2.16797 10.0003C2.16797 5.66813 5.66911 2.16699 10.0013 2.16699C14.3335 2.16699 17.8346 5.66813 17.8346 10.0003C17.8346 14.3325 14.3335 17.8337 10.0013 17.8337C5.66911 17.8337 2.16797 14.3325 2.16797 10.0003Z" stroke="#636363" />
                            </svg>


                        </div>
                        <div>
                            <div className='frstname' >
                                Remove From Library
                            </div>

                        </div>

                    </div>
                    {/* <div className=' display-1 cursor'  >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2807)">
                                    <path d="M17.5 6.875V15.6945C17.5 15.8419 17.4415 15.9831 17.3373 16.0873C17.2331 16.1915 17.0919 16.25 16.9445 16.25H3.125C2.95924 16.25 2.80027 16.1842 2.68306 16.0669C2.56585 15.9497 2.5 15.7908 2.5 15.625V5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H7.29141C7.42664 4.375 7.55822 4.41886 7.66641 4.5L10 6.25H16.875C17.0408 6.25 17.1997 6.31585 17.3169 6.43306C17.4342 6.55027 17.5 6.70924 17.5 6.875Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2807">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div>
                            <div className='frstname' >
                                Move to folder
                            </div>

                        </div>

                    </div>
                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2811)">
                                    <path d="M17.9422 6.68291C18.0594 6.56571 18.1252 6.40681 18.1252 6.24112C18.1252 6.07543 18.0594 5.91652 17.9422 5.79932L13.2032 1.05791C13.086 0.940792 12.9271 0.875 12.7614 0.875C12.5957 0.875 12.4368 0.940792 12.3196 1.05791L7.84303 5.54698C7.84303 5.54698 5.67506 4.46338 3.35943 6.33213C3.29077 6.3871 3.23447 6.45593 3.19424 6.53415C3.154 6.61236 3.13072 6.69818 3.12593 6.78601C3.12114 6.87383 3.13493 6.96168 3.16642 7.04381C3.19791 7.12593 3.24637 7.20049 3.30865 7.2626L11.7383 15.6915C11.8015 15.7542 11.8773 15.8027 11.9606 15.8337C12.044 15.8647 12.133 15.8776 12.2218 15.8715C12.3105 15.8653 12.3969 15.8403 12.4752 15.7981C12.5535 15.7559 12.6219 15.6974 12.6758 15.6267C13.3313 14.7548 14.361 12.9634 13.4657 11.1735L17.9422 6.68291Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.52266 11.4775L3.75 15.2502" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2811">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <div>
                            <div className='frstname' >
                                Pin playlist
                            </div>

                        </div>

                    </div>
                    <hr style={{ borderColor: '#ffffff5c' }} />
                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2816)">
                                    <path d="M2.41876 14.525C3.70469 13.1555 7.08048 10.25 11.8773 10.25V14L18.1273 7.75L11.8773 1.5V5.25C7.75235 5.25 2.46407 9.19141 1.87735 14.2766C1.86923 14.3424 1.88228 14.4091 1.9146 14.4671C1.94693 14.525 1.99686 14.5712 2.05717 14.5988C2.11747 14.6265 2.18502 14.6343 2.25003 14.621C2.31504 14.6077 2.37413 14.5741 2.41876 14.525Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2816">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>


                        </div>
                        <div>
                            <div className='frstname' >
                                Share
                            </div>

                        </div>

                    </div> */}
                </div>
            </Popover>
            {/* <Modal
                open={model}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal-style"
            >
                <Box sx={style}>
                    <div className="display-2">
                        <div className="edtdet">
                            Edit details
                        </div>
                        <div className="cls" onClick={handleClose2}>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.0625 0.9375L0.9375 9.0625M0.9375 0.9375L9.0625 9.0625" stroke="#4C4C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                    </div>
                    <div className="margin-top display-1">

                        <Button
                            className='lbk'
                            component="label"
                            role={undefined}
                            // variant="contained"
                            tabIndex={-1}
                        // startIcon={<CloudUploadIcon />}
                        >
                            <div className="palylistimg cursor">
                                <img src={imageUrl ? imageUrl : playlist} alt="playlist" />
                            </div>
                            <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                            <div className='chsimg'>
                                Choose Image
                            </div>
                        </Button>
                        <div className="mdoel-inpu">
                            <TextField id="outlined-basic" placeholder="Name" variant="outlined" />
                            <div className="mdoel-text">
                                <TextField
                                    id="outlined-multiline-static"
                                    placeholder="Description"
                                    multiline
                                    rows={4}
                                // defaultValue="Default Value"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sve">
                        <Button>
                            Save
                        </Button>
                    </div>
                    <div className="by-precee" style={{ color: 'var(--clr-font)' }}>
                        By proceeding, you agree to give Dreamster access to the image you choose to Upload Please make sure you have the right to upload the image.
                    </div>
                </Box>
            </Modal> */}
        </div >
    )
}

export default DynamicCut
